import React from 'react'
import {
  FcTodoList,
  FcDataProtection,
  FcDocument,
  FcAdvertising,
  FcEngineering,
  FcServices
} from "react-icons/fc";
import { Link } from "react-router-dom";

const Settings = () => {
  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>הגדרות מערכת</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>

      <div className="row" style={{ padding: 20 }}>
      
      <div className="col-lg-3">
          <Link to="/mycompany" style={{textDecoration:'none'}}>
            <div className="card" style={{textAlign:'center', alignItems:'center', padding:10}}>
              <FcServices size={80} />
              <div className="card-body">
                <h5 className="card-title">פרטי החברה שלי</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-3">
          <Link to="/safety-reports-item" style={{textDecoration:'none'}}>
            <div className="card" style={{textAlign:'center', alignItems:'center', padding:10}}>
              <FcTodoList size={80} />
              <div className="card-body">
                <h5 className="card-title">הגדרות ממצאי בטיחות</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-3">
          <Link to="/safety-reports-areas" style={{textDecoration:'none'}}>
            <div className="card" style={{textAlign:'center', alignItems:'center', padding:10}}>
              <FcDataProtection size={80} />
              <div className="card-body">
                <h5 className="card-title">תחומי בטיחות</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-3">
          <Link to="/trainings-categories" style={{textDecoration:'none'}}>
            <div className="card" style={{textAlign:'center', alignItems:'center', padding:10}}>
              <FcDocument size={80} />
              <div className="card-body">
                <h5 className="card-title">תחומי הדרכות</h5>
              </div>
            </div>
          </Link>
        </div>

          

      </div>
      <div className='row' style={{ padding: 20 }}>

      <div className="col-lg-3">
          <Link to="/periodic-followup" style={{textDecoration:'none'}}>
            <div className="card" style={{textAlign:'center', alignItems:'center', padding:10}}>
              <FcAdvertising size={80} />
              <div className="card-body">
                <h5 className="card-title">ניהול מעקבים</h5>
              </div>
            </div>
          </Link>
        </div>


      <div className="col-lg-3">
        <Link to="/safety-testing" style={{textDecoration:'none'}}>
            <div className="card" style={{textAlign:'center', alignItems:'center', padding:10}}>
              <FcEngineering size={80} />
              <div className="card-body">
                <h5 className="card-title">קטגוריות בדיקות בטיחות</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-3">
        {/* <Link to="/assets-categories" style={{textDecoration:'none'}}>
            <div className="card" style={{textAlign:'center', alignItems:'center', padding:10}}>
              <FcInspection size={80} />
              <div className="card-body">
                <h5 className="card-title">ניהול מחלקות אביזרים</h5>
              </div>
            </div>
          </Link> */}
        </div>


      </div>
    </>
  )
}

export default Settings