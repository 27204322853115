import React, { useState, useEffect, useCallback } from "react";
import { auth, database } from "../../services/firebase-config";
import {
  requestDataByAssociate,
  getAccountDetails,
} from "../../services/dbHelper";
import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import PeriodicItem from "./components/PeriodicItem";
import useDashboardStore from "../../store/useDashboardStore";
import { requestSuperData } from "../../store/dashboardHook";
import PeriodicItemAdmin from "./components/PeriodicItemAdmin";
import { FaQuestionCircle } from "react-icons/fa";


const PeriodicFollowup = () => {
  const months = Array.from({ length: 60 }, (_, i) => i + 1);
  const { userPersonalData } = useDashboardStore();
  const [trackingName, setTrackingName] = useState("");
  const [periodicTime, setPeriodicTime] = useState("");
  const [trackingDesc, setTrackingDesc] = useState("");
  const [myAccount, setMyAccount] = useState("");
  const [periodicList, setPeriodicList] = useState([]);
  const [periodicListAdmin, setPeriodicListAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [adminCategory, setAdminCategory] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const account = await getAccountDetails(auth.currentUser.uid);
      const periodicTrackArr = await requestDataByAssociate(
        account.associateId,
        "periodicTracking"
      );
      const periodicTrackArrFiltered = periodicTrackArr.filter(
        (x) => x.adminCategory === false
      );
      const periodicTrackArrAdmin = await requestSuperData("periodicTracking");
      const periodicTrackArrAdminFiltered = periodicTrackArrAdmin.filter(
        (x) => x.adminCategory === true
      );
      setPeriodicList(periodicTrackArrFiltered);
      setPeriodicListAdmin(periodicTrackArrAdminFiltered);
      setMyAccount(account);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const addTracking = async () => {
    if (trackingName !== "" && periodicTime !== "") {
      try {
        await addDoc(collection(database, "periodicTracking"), {
          createdAt: Date.now(),
          trackingName: trackingName,
          trackingDesc: trackingDesc,
          periodicTime: periodicTime,
          associateId: myAccount.associateId,
          templateId: "",
          adminCategory: adminCategory,
        });
        setIsLoading(false);
        setTrackingName("");
        setTrackingDesc("");
        setPeriodicTime("");
        loadData();
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא להקליד שם למעקב ואת מספר החודשים לביקורת");
    }
  };

  const addTemplateToMyList = async (item) => {
    try {
      await addDoc(collection(database, "periodicTracking"), {
        createdAt: Date.now(),
        trackingName: item.trackingName,
        trackingDesc: item.trackingDesc,
        periodicTime: item.periodicTime,
        associateId: myAccount.associateId,
        templateId: item.id,
        adminCategory: false,
      });
      loadData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteTrack = async (id) => {
    const isConfirmed = window.confirm("האם למחוק את סוג המעקב לצמיתות?");
    if (isConfirmed) {
      try {
        await deleteDoc(doc(database, "periodicTracking", id)).then(
          (result) => {
            loadData();
          }
        );
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const updateTrack = async (id, trackingName, periodicTime, trackingDesc) => {
    const trackingRef = doc(database, "periodicTracking", id);
    try {
      await updateDoc(trackingRef, {
        trackingName: trackingName,
        periodicTime: periodicTime,
        trackingDesc: trackingDesc,
      });
      loadData();
    } catch (error) {
      toast.error(error.message);
    }
  };


  function handleInputChange(e) {
    const value = e.target.value;
    const numericValue = parseInt(value, 10);
    if (value === "" || (numericValue <= 60 && value.length <= 2)) {
        setPeriodicTime(value);
    }
}

const [showTooltip1, setShowTooltip1] = useState(false);
const toggleTooltip1 = () => setShowTooltip1(!showTooltip1);

const [showTooltip2, setShowTooltip2] = useState(false);
const toggleTooltip2 = () => setShowTooltip2(!showTooltip2);


  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>ניהול מעקבים</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />



      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-3">
          <h6>הוספת סוג תסקיר חדש <FaQuestionCircle onClick={toggleTooltip1} style={{ cursor: 'pointer' }} size={20} color="#0e6da8" />
          {showTooltip1 && (
            <div style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background: 'white' }}>
              <p className="tooltip-text">הסבר אודות הוספת סוג תסקיר חדש</p>
            </div>
          )}</h6>
          <br />

          <label>שם התסקיר</label>
          <input
            type="text"
            value={trackingName}
            onChange={(e) => {
              setTrackingName(e.target.value);
            }}
            className="form-control"
          />

          <label>תיאור התסקיר</label>
          <input
            type="text"
            value={trackingDesc}
            onChange={(e) => {
              setTrackingDesc(e.target.value);
            }}
            className="form-control"
          />

          <label>תקופת מעקב בחודשים (עד 60 חודשים)</label>
          <input
            type="number"
            max="60"
            value={periodicTime}
            onChange={handleInputChange}
            className="form-control"
          />
          <br />

          <div className="col-lg-12">
            {userPersonalData.role === "superadmin" && (
              <>
                <input
                  onChange={(e) => setAdminCategory(e.target.checked)}
                  checked={adminCategory}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                />{" "}
                <label className="form-check-label">
                  הוסף קטגוריה לכל המשתמשים
                </label>
              </>
            )}
          </div>
          <br />

          <button onClick={addTracking} className="btn btn-success">
            שמירת מעקב
          </button>
        </div>

        <div className="col-lg-9">
          <h6>מעקבים תקופתיים <FaQuestionCircle onClick={toggleTooltip2} style={{ cursor: 'pointer' }} size={20} color="#0e6da8" />
          {showTooltip2 && (
            <div style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background: 'white' }}>
              <p className="tooltip-text">הסבר אודות מעקבים תקופתיים</p>
            </div>
          )}</h6>
          <br />
          <p style={{ marginBottom: 20 }}><b>הקטגוריות שלי</b></p>
          {periodicList.length > 0 && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">שם התסקיר</th>
                  <th scope="col">תיאור</th>
                  <th scope="col">תקופת מעקב בחודשים</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {periodicList.map((item) => (
                  <PeriodicItem
                    key={item.id}
                    item={item}
                    deleteTrack={deleteTrack}
                    updateTrack={updateTrack}
                  />
                ))}
              </tbody>
            </table>
          )}

          <p style={{ marginBottom: 12, marginTop: 20 }}><b>קטגוריות מערכת</b></p>
          {periodicListAdmin.length > 0 && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">שם התסקיר</th>
                  <th scope="col">תיאור</th>
                  <th scope="col">תקופת מעקב בחודשים</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {periodicListAdmin
                  .filter(
                    (adminItem) =>
                      !periodicList.some(
                        (personalItem) =>
                          personalItem.templateId === adminItem.id
                      )
                  )
                  .map((item) => (
                    <PeriodicItemAdmin
                      key={item.id}
                      item={item}
                      role={userPersonalData.role}
                      addTemplateToMyList={addTemplateToMyList}
                      deleteTrack={deleteTrack}
                      updateTrack={updateTrack}
                    />
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default PeriodicFollowup;
