import React, { useState, useEffect, useRef } from "react";
import { auth, database, storage } from "../../services/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, query, where, doc, deleteDoc, updateDoc, getDocs } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import MachineReportItem from "../../components/MachineReportItem";
import useDashboardStore from "../../store/useDashboardStore";
import {saveLog} from '../../services/createLog'
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { FaRegFilePdf } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import MachineReportItemExp from "../../components/MachineReportItemExp";



const Machines = () => {
  const {
    userPersonalData,
    selectedCompany,
    myCompany,
    companies,
    machineReports,
    periodics,
    addMachineReport,
    updateMachineReport,
    removeMachineReportById,
    myPlan,
    updateInactiveReports
  } = useDashboardStore();

  const [isLoading, setIsLoading] = useState(false);
  const [reportsArr, setReportsArr] = useState([]);
  const [reportsArrOrig, setReportsArrOrig] = useState([]);   
  const today = new Date();
  const todayMax = new Date().toISOString().split("T")[0];
  const [reportType, setreportType] = useState("");
  const [machines, setMachines] = useState([]);
  const [machinesOrig, setMachinesOrig] = useState([]);
  const [checkType, setCheckType] = useState("");
  const [progress, setProgress] = useState(0);
  const [reportComments, setReportComments] = useState("");
  const [associateMachineId, setAssociateMachineId] = useState(null);
  const [reportDate, setReportDate] = useState(today);
  const [nextReportDate, setNextReportDate] = useState(today);
  const fileInputRef = useRef(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportTypeFilter, setReportTypeFilter] = useState("");
  const modalRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [pdfPreviewUrls, setPdfPreviewUrls] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const exportRef = React.useRef();


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setPdfPreviewUrls(prevFiles => [...prevFiles, ...newFiles]);
    }
  });
  
  const exportToExcel = (reportsArr) => {
    const date = new Date();
    const timestamp = date.getTime(); // This gives you the date as a long number
    const fileName = `MachineReports_${timestamp}.xlsx`;

    // Define headers in the order you want them to appear (right to left)
    const headers = [
        { header: 'קבצים בדוח', key: 'reportFiles', width: 10 },
        { header: 'הערות', key: 'reportComments', width: 10 },
        { header: 'מחלקה', key: 'department', width: 10 },
        { header: 'הבדיקה הבאה', key: 'nextReportDate', width: 15 },
        { header: 'תאריך הבדיקה', key: 'reportDate', width: 15 },
        { header: 'נוצר בתאריך', key: 'createdAt', width: 15 },
        { header: 'קטגוריה', key: 'reportType', width: 10 },
        { header: 'סוג הבדיקה', key: 'checkType', width: 10 },
        { header: 'מספר מזהה', key: 'id', width: 10 },
    ];

    // Convert reportsArr to a format suitable for Excel, including formatting dates
    const data = reportsArr.map(report => ({
        id: report.id,
        checkType: report.checkType,
        reportType: report.reportType,
        createdAt: moment(report.createdAt).format('DD/MM/YYYY'),
        reportDate: moment(report.reportDate).format('DD/MM/YYYY'),
        nextReportDate: moment(report.nextReportDate).format('DD/MM/YYYY'),
        department: report.department,
        reportComments: report.reportComments,
        reportFiles: report.reportFiles.length
    }));








    // Create a new worksheet and apply the headers
    const ws = XLSX.utils.json_to_sheet(data, { header: headers.map(header => header.key), skipHeader: true });

    // Manually add the headers to the first row
    for (let i = 0; i < headers.length; i++) {
        ws[XLSX.utils.encode_cell({ c: i, r: 0 })] = { v: headers[i].header };
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reports");

    // Write the workbook file
    XLSX.writeFile(wb, fileName);
};



useEffect(() => {
  // Ensure all data are arrays even if they might be undefined
  const departments = selectedCompany?.departments || [];
  const machines = selectedCompany?.machines || [];

  setDepartmentList(departments);
  setMachines(machines);
  setMachinesOrig([...machines]); // Cloning to avoid direct state mutation issues

  const reports_val = (machineReports || [])
    .filter(x => x.associateCompanyId === selectedCompany?.id && x.isActive)
    .sort((a, b) => (a.nextReportDate || 0) - (b.nextReportDate || 0)); // Ensure nextReportDate is a number

  setReportsArrOrig(reports_val);
  setReportsArr(reports_val);

}, [machineReports, selectedCompany]);


  useEffect(() => {
    if (reportType !== "") {
      const monthsToAdd = parseInt(periodics?.find(x => x.trackingName === reportType)?.periodicTime, 10) || 0;
      const origDate = new Date(reportDate);
      let targetYear = origDate.getFullYear();
      let targetMonth = origDate.getMonth() + monthsToAdd;
      let targetDay = origDate.getDate();

      while (targetMonth > 11) {
        targetYear++;
        targetMonth -= 12;
      }
      const newDate = new Date(targetYear, targetMonth, targetDay);
      setNextReportDate(newDate);
    }
  }, [reportDate, reportType, periodics]);

  const updateListByDate = (toDateVal) => {
    setToDate(toDateVal);
    const fromDateTimestamp = new Date(fromDate).getTime();
    const toDateTimestamp = new Date(toDateVal).getTime();

    if (reportTypeFilter !== "") {
      const filteredCommittees = reportsArrOrig.filter(
        (committee) =>
          committee.reportDate >= fromDateTimestamp &&
          committee.reportDate <= toDateTimestamp &&
          committee.reportType === reportTypeFilter
      );
      setReportsArr(filteredCommittees);
    } else {
      const filteredCommittees = reportsArrOrig.filter(
        (committee) =>
          committee.reportDate >= fromDateTimestamp &&
          committee.reportDate <= toDateTimestamp
      );

      setReportsArr(filteredCommittees);
    }
  };

  const applyFilters = () => {
    let filterMachinesReports = reportsArrOrig;

    if (selectedCompany) {
      filterMachinesReports = filterMachinesReports.filter(report => report.associateCompanyId === selectedCompany.id);
    }

    if (reportType) {
      filterMachinesReports = filterMachinesReports.filter(report => report.reportType === reportType);
    }

    if (selectedCategory) {
      filterMachinesReports = filterMachinesReports.filter(report => report.department === selectedCategory);
    }

    if (fromDate) {
      const fromTimestamp = new Date(fromDate).getTime();
      filterMachinesReports = filterMachinesReports.filter(report => new Date(report.createdAt).getTime() >= fromTimestamp);
    }
    if (toDate) {
      const toTimestamp = new Date(toDate).getTime();
      filterMachinesReports = filterMachinesReports.filter(report => new Date(report.createdAt).getTime() <= toTimestamp);
    }
    setReportsArr(filterMachinesReports);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedCompany, selectedCategory, reportType, fromDate, toDate, reportsArrOrig]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const handleHide = () => {
        document.body.classList.remove("modal-open");
        document.querySelector(".modal-backdrop.fade.show")?.remove();
      };
      modalElement.addEventListener("hide.bs.modal", handleHide);
      return () => {
        modalElement.removeEventListener("hide.bs.modal", handleHide);
      };
    }
  }, []);








  const addNewReport = async () => {
    if (
      pdfPreviewUrls.length > 0 &&
      reportType !== "" &&
      checkType !== "" &&
      reportDate !== "" &&
      associateMachineId !== "" &&
      selectedCompany !== "" &&
      selectedCategory !== ""
    ) {
      setIsLoading(true);
  
      try {
        // 1. Deactivate any existing active report with the same associateMachineId
        const reportsRef = collection(database, "machines_reports");
        const activeReportQuery = query(
          reportsRef,
          where("associateMachineId", "==", associateMachineId),
          where("isActive", "==", true)
        );
  
        const querySnapshot = await getDocs(activeReportQuery);
        const updatedReports = [];
  
        if (!querySnapshot.empty) {
          const updatePromises = querySnapshot.docs.map((doc) => {
            const updatedData = { ...doc.data(), isActive: false, id: doc.id };
            updatedReports.push(updatedData);
  
            // Update isActive to false in Firestore
            return updateDoc(doc.ref, { isActive: false });
          });
          await Promise.all(updatePromises);
        }
  
        // Update inactive reports in Zustand store
        updateInactiveReports(updatedReports);
  
        // 2. Upload files and create new report
        const uploadTasks = await Promise.all(
          pdfPreviewUrls.map((file) =>
            uploadBytesResumable(ref(storage, `files/${file.name}`), file)
          )
        );
  
        const downloadUrls = await Promise.all(
          uploadTasks.map((task) => getDownloadURL(task.ref))
        );
  
        const newReport = {
          createdBy: auth.currentUser.uid,
          associateCompanyId: selectedCompany.id,
          associateId: userPersonalData.associateId,
          createdAt: Date.now(),
          reportType,
          checkType,
          reportComments,
          reportFiles: downloadUrls,
          associateMachineId,
          reportDate: new Date(reportDate).getTime(),
          nextReportDate: new Date(nextReportDate).getTime(),
          department: selectedCategory,
          isActive: true,
        };
  
        // Add the new report to Firestore
        const docRef = await addDoc(reportsRef, newReport);
        newReport.id = docRef.id;
  
        // Add new report to Zustand store
        addMachineReport(newReport);
  
        // 3. Update component's local state to reflect the latest reports
        setReportsArr((prev) => [
          ...prev.filter((report) => report.associateMachineId !== associateMachineId),
          ...updatedReports,
          newReport,
        ]);
  
        setReportsArrOrig((prev) => [
          ...prev.filter((report) => report.associateMachineId !== associateMachineId),
          ...updatedReports,
          newReport,
        ]);
  
        // Log data creation
        const machineItem = machines.find((x) => x.empId === associateMachineId);
        const logData = {
          associateCompanyId: selectedCompany.id,
          associateId: userPersonalData.associateId,
          createdBy: userPersonalData.uuid,
          logTitle: "תסקיר חדש",
          logDescription: `${userPersonalData.firstName} ${userPersonalData.lastName} יצר תסקיר חדש עבור ${selectedCompany.companyName}, סוג הבדיקה: ${checkType} לאביזר: ${machineItem.machineType} ${machineItem.machineSerialNumber}`
        };
        saveLog(logData);
  
        // Reset form fields
        resetFormFields();
  
        toast.success("התסקיר התווסף בהצלחה");
      } catch (error) {
        console.error("Error adding report: ", error.message);
        toast.error("ניסיון הוספת תסקיר נכשל");
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("יש למלא את שדות החובה בטופס");
    }
  };

  


  
  
  // Helper function to reset form fields
  const resetFormFields = () => {
    setCheckType("");
    setReportComments("");
    setreportType("");
    setAssociateMachineId("");
    setSelectedCategory("");
    setReportDate(today);
    setNextReportDate(today);
    setPdfPreviewUrls([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  



  

  const removeReport = async (report) => {

    const isConfirmed = window.confirm("האם למחוק את התסקיר לצמיתות?");
    if (isConfirmed) {
    try {
      await deleteDoc(doc(database, "machines_reports", report.id)).then((res) => {
        removeMachineReportById(report.id);
        toast.success("התסקיר הוסר בהצלחה");
      }).catch((err) => {
        toast.error(err.message);
      });
    } catch (error) {
      toast.error(error.message);
    }
  }
  };

  const updateReport = async (
    selectedCategory,
    reportType,
    checkType,
    reportComments,
    reportFile,
    reportDate,
    nextReportDate,
    id
  ) => {
    const updateData = {
      checkType,
      nextReportDate: new Date(nextReportDate).getTime(),
      reportComments,
      reportDate: new Date(reportDate).getTime(),
      reportType,
      department: selectedCategory
    };

    if (reportFile) {
      const sotrageRef = ref(storage, `files/${reportFile.name}`);
      const uploadTask = uploadBytesResumable(sotrageRef, reportFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then(async (downloadURL) => {
              const committeesRef = doc(database, "machines_reports", id);
              await updateDoc(committeesRef, {
                ...updateData,
                reportFile: downloadURL
              }).then(() => {
                updateMachineReport(id, { ...updateData, reportFile: downloadURL });
              });
            });
        }
      );
    } else {
      const committeesRef = doc(database, "machines_reports", id);
      await updateDoc(committeesRef, updateData).then(() => {
        updateMachineReport(id, updateData);
      });
    }
  };

  const duplicateReport = async (report) => {
    try {
      const newReport = { ...report, id: undefined }; // Remove the id to create a new document
      const docRef = await addDoc(collection(database, "machines_reports"), newReport);
      newReport.id = docRef.id;
      addMachineReport(newReport);
      toast.success("תסקיר הועתק בהצלחה");
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (!reportType) {
      setMachines(machinesOrig);
    } else {
      const filteredMachines = machinesOrig.filter((machine) => machine.machineType === reportType);
      setMachines(filteredMachines);
    }
  }, [reportType, machinesOrig]);

  useEffect(() => {
    return () => {
      pdfPreviewUrls.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [pdfPreviewUrls]);







  const handleDownloadPdf = async () => {
    const element = exportRef.current; // Get the element to export
    const canvas = await html2canvas(element, { scale: 1, useCORS: true }); // Generate canvas from DOM
    const imgData = canvas.toDataURL("image/png"); // Convert canvas to data URL

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190; // A4 width minus some margin
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjusted height based on width
    const pageHeight = pdf.internal.pageSize.height - 20; // A4 height minus some margin for footer

    let heightLeft = imgHeight;
    let position = 0; // Start position for drawing

    // Add the first page with content
    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add subsequent pages if needed
    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft); // Adjust position for next slice
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Add footer on each page
    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8); // Footer font size
      pdf.setTextColor(100); // Footer text color
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        {
          align: "center",
        }
      );
    }

    const timestamp = new Date().getTime();
    pdf.save(`report_${timestamp}.pdf`);
  };
  const handleModal = async () => {
    setShowModal(true); // Open modal to ensure the content is visible for screenshot
  };




  
  

  return (
    <>
      <ToastContainer />
      
      <div ref={modalRef} className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">הוספת תסקיר חדש</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div className="modal-body">
              <div className="row">


                <div className="col-lg-6">
                  <label className="form-label">סוג התסקיר <span style={{ color: "red" }}>*</span></label>
                  <select
                    value={reportType}
                    className="form-control"
                    onChange={(e) => { setreportType(e.target.value); }}>
                    <option value="">נא לבחור מהרשימה</option>
                    {
                      periodics?.map(item => (
                        <option value={item.trackingName} key={item.id}>{item.trackingName}</option>
                      ))
                    }
                  </select>
                </div>


                <div className="col-lg-6">
                  <label className="form-label">
                    סוג הבדיקה <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    value={checkType}
                    className="form-control"
                    onChange={(e) => {
                      setCheckType(e.target.value);
                    }}>
                    <option value="">נא לבחור מהרשימה</option>
                    <option value="בדיקה תקופתית">בדיקה תקופתית</option>
                    <option value="בדיקה ראשונה">בדיקה ראשונה</option>
                    <option value="בדיקה מיוחדת">בדיקה מיוחדת</option>
                  </select>
                </div>


              </div>

              <div className="row">


                <div className="col-lg-6">
                  <label className="form-label">בחר מחלקה <span style={{ color: "red" }}>*</span></label>
                  <select
                    value={selectedCategory}
                    className="form-control"
                    onChange={(e) => { setSelectedCategory(e.target.value) }}>
                    <option value="">בחר מחלקה מהרשימה</option>
                    {machines.length > 0 && (
                      <>
                        {departmentList.map((depart) => (
                          <option key={depart.empId} value={depart.departmentName}>
                            {depart.departmentName}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>



                <div className="col-lg-6">
                  <label className="form-label">בחירת מכונה / אביזר <span style={{ color: "red" }}>*</span></label>
                  <select
                    value={associateMachineId}
                    className="form-control"
                    onChange={(e) => {
                      setAssociateMachineId(e.target.value);
                    }}>
                    <option value="">בחר מכונה מהרשימה</option>
                    {machines.length > 0 && (
                      <>
                        {machines.map((machine) => (
                          <option value={machine.empId}>
                            {machine.machineType} | {machine.machineSerialNumber} | {machine.machineLicenseNumber}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">
                    תאריך הסקירה <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    max={todayMax}
                    type="date"
                    className="form-control"
                    value={moment(reportDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setReportDate(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label">תאריך הסקירה הבאה</label>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(nextReportDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setNextReportDate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <label className="form-label">תיאור הסקירה</label>
              <textarea
                className="form-control"
                value={reportComments}
                onChange={(e) => {
                  setReportComments(e.target.value);
                }}
              ></textarea>

              <label className="form-label">העלאת קובץ <span style={{ color: "red" }}>*</span></label>


              <div {...getRootProps({ className: 'dropzone', style: dropzoneStyle })}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>ניתן לגרור קבצים לכאן או להקליק כאן על מנת לבחור קבצים ידנית</p>
                }
              </div>

              <div style={thumbsContainer}>
                {pdfPreviewUrls.map((file, index) => (
                  <div style={thumb} key={index}>
                    <div style={thumbInner}>
                      <iframe
                        src={file.preview}
                        style={img}
                        frameBorder="0"
                        title={`PDF Preview ${index + 1}`}
                      ></iframe>
                    </div>
                  </div>
                ))}
              </div>

            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">סגירה</button>
              <button onClick={addNewReport} type="button" className="btn btn-success">הוספת תסקיר חדש</button>
            </div>

          </div>
        </div>
      </div>

      <div className="row heading-container">
        <div className="col-lg-9">
          <h3>מעקבים תקופתיים | {selectedCompany.companyName}</h3>
        </div>
        <div className="col-lg-3 heading-btn-container">
          <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-success btn-lg">הוספת תסקיר חדש</button>
          {
            myPlan.actions && <>
            <button style={{marginRight:10}} onClick={handleModal} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
            <button className="btn btn-success btn-lg" style={{marginRight:10}} onClick={() => {exportToExcel(reportsArr)}}><PiMicrosoftExcelLogoFill color="#ffffff" size={30} /></button>
            </>
          }
          
        </div>
      </div>

      <div className="row heading-container-filter">
        <div className="col-lg-2">
          <label className="form-label">מחלקה</label>
          <select
            className="form-control"
            onChange={(e) => { setSelectedCategory(e.target.value) }}>
            <option value="">בחירה מהרשימה...</option>
            {
              departmentList.map((depart) => (
                <option value={depart.departmentName} key={depart.empId}>{depart.departmentName}</option>
              ))
            }
          </select>
        </div>

        <div className="col-lg-2">
          <label className="form-label">סוג התסקיר</label>
          <select
            className="form-control"
            onChange={(e) => { setreportType(e.target.value) }}>
            <option value="">בחירה מהרשימה...</option>
            {
              periodics.length > 0 && (<>
                {
                  periodics?.map(item => (
                    <option value={item.trackingName} key={item.id}>{item.trackingName}</option>
                  ))
                }
              </>)
            }
          </select>
        </div>

        <div className="col-lg-2">
          <label className="form-label">מתאריך</label>
          <input
            type="date"
            className="form-control"
            value={moment(fromDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-2">
          <label className="form-label">עד תאריך</label>
          <input
            type="date"
            className="form-control"
            value={moment(toDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              updateListByDate(e.target.value);
            }}
          />
        </div>

        <div className="col-lg-2"></div>
        <div className="col-lg-2"></div>
      </div>

      <div className="row" style={{ padding: 20 }}>
        <div className="col-lg-12">
          {reportsArr.length > 0 && (
            <>
              {reportsArr.map((machine) => (
                <MachineReportItem
                  key={machine.id}
                  departmentList={departmentList}
                  periodicList={periodics}
                  updateReport={updateReport}
                  removeReport={removeReport}
                  machine={machine}
                  machines={machines}
                  companiesArr={companies}
                />
              ))}
            </>
          )}
        </div>
      </div>






      <Modal
        show={showModal}
        dialogClassName="custom-modal"
        onHide={() => setShowModal(false)}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" ref={exportRef} style={{ padding: 50 }}>
            
            <div className="row">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-2">
                    <img src="/images/logo_black.png" style={{ width: 60 }} />
                  </div>
                  <div className="col-lg-10">
                    <p className="companyInfo">
                      דוח בטיחות זה
                      <br />
                      הופק באמצעות
                      <br />
                      מערכת לממונה
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                {myCompany && myCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={myCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{myCompany.companyName}</b>
                          <br />
                          {myCompany.companyAddress}, {myCompany.companyCity}
                          <br />
                          ח.פ. {myCompany.companyTaxId}
                          <br />
                          {myCompany.companyEmail} | {myCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-lg-4">
                {selectedCompany && selectedCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={selectedCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{selectedCompany.companyName}</b>
                          <br />
                          {selectedCompany.companyAddress},{" "}
                          {selectedCompany.companyCity}
                          <br />
                          ח.פ. {selectedCompany.companyTaxId}
                          <br />
                          {selectedCompany.companyEmail} |{" "}
                          {selectedCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 50, marginBottom:50 }}>
              <div className="col-lg-12">
                <h2>מעקבים תקופתיים | {selectedCompany.companyName}</h2>
              </div>
            </div>

            {reportsArr.length > 0 && (
            <>
              {reportsArr.map((machine) => (
                <MachineReportItemExp
                  key={machine.id}
                  departmentList={departmentList}
                  periodicList={periodics}
                  duplicateReport={duplicateReport}
                  updateReport={updateReport}
                  removeReport={removeReport}
                  machine={machine}
                  machines={machines}
                  companiesArr={companies}
                />
              ))}
            </>
          )}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleDownloadPdf}
            className="btn btn-success btn-lg">שמירת קובץ</button>
        </Modal.Footer>
      </Modal>




    </>
  );
};



const dropzoneStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginBottom: '20px'
};
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};
const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: '100%',
  height: 200,
  padding: 4,
  boxSizing: 'border-box'
};
const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  width:'100%'
};
const img = {
  display: 'block',
  width: '100%',
  height: '100%'
};



export default Machines;
