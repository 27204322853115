import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database, storage } from "../../services/firebase-config";
import {collection,addDoc,doc,deleteDoc,updateDoc} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import FindingItem from "./components/FindingItem";
import useDashboardStore from "../../store/useDashboardStore";
import {saveLog} from '../../services/createLog'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { FaRegFilePdf } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import FindingItemExp from "./components/FindingItemExp";
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";

const FindingList = () => {

  const navigate = useNavigate();

  const { userPersonalData,myCompany, selectedCompany, myPlan, selectedSite, employees, companies, findings, safetyReportsAreas } = useDashboardStore();

  const [showModal, setShowModal] = useState(false);
  const exportRef = React.useRef();
  const modalRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState(selectedCompany.projects);
  const [findingsType, setFindingsType] = useState(safetyReportsAreas);
  const [findingsList, setFindingsList] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [inputKey, setInputKey] = useState(Date.now());
  const [itemImpairmentSeverity, setItemImpairmentSeverity] = useState("");
  const [itemImpairmentArea, setItemImpairmentArea] = useState("");
  const [itemImpairmentDesc, setItemImpairmentDesc] = useState("");
  const [itemImpairmentComments, setItemImpairmentComments] = useState("");
  const [itemImpairmentLocation, setItemImpairmentLocation] = useState("");
  const [itemImpairmentTreat, setItemImpairmentTreat] = useState("");
  const [itemImpairmentTrack, setItemImpairmentTrack] = useState("");
  const [itemImpairmentImgReq, setItemImpairmentImgReq] = useState(false);
  const [itemImpairmentCommReq, setItemImpairmentCommReq] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [severityFilter, setSeverityFilter] = useState("");
  const [targetDate, setTargetDate] = useState("");
  const fileInputRef = useRef(null);
  const [filteredEmployees, setFilteredEmployees] = useState([])
  const [filterArea, setFilterArea] = useState("");

  useEffect(() => {
    const femp = employees.filter(x => x.associateCompany === selectedCompany.id);
    setFilteredEmployees(femp)
  },[])

  const updateListByDate = (toDateVal) => {
    setToDate(toDateVal);
    const fromDateTimestamp = new Date(fromDate).getTime();
    const toDateTimestamp = new Date(toDateVal).getTime();
    const filteredCommittees = findings.filter(
      (report) =>
        report.createdAt >= fromDateTimestamp &&
        report.createdAt <= toDateTimestamp
    );
    setFindingsList(filteredCommittees);
  };
  function generateRandomId() {
    return Math.random().toString(36).substr(2, 10);
  }

  const addNewFinding = async () => {
    setIsLoading(true);
    if (
      itemImpairmentArea !== "" &&
      itemImpairmentLocation !== "" &&
      itemImpairmentSeverity !== "" &&
      itemImpairmentTrack !== "" &&
      itemImpairmentTreat !== "" &&
      targetDate !== ""
    ) {
      try {
        let gallery = [];
        if (downloadURLs.length > 0) {
          downloadURLs.map((img) => {
            const image = {
              imgId: generateRandomId(),
              imageSource: img,
            };
            gallery.push(image);
          });
        }

        const vdate = new Date(targetDate);

        const new_finding = {
          gallery: gallery,
          itemImpairmentArea: itemImpairmentArea,
          itemImpairmentCommReq: itemImpairmentCommReq,
          itemImpairmentComments: itemImpairmentComments,
          itemImpairmentDesc: itemImpairmentDesc,
          itemImpairmentImgReq: itemImpairmentImgReq,
          itemImpairmentLocation: itemImpairmentLocation,
          itemImpairmentSeverity: itemImpairmentSeverity,
          itemImpairmentTrack: itemImpairmentTrack,
          itemImpairmentTreat: itemImpairmentTreat,
          status: "פעיל",
          createdBy: userPersonalData.id,
          associateId: userPersonalData.associateId,
          site: selectedSite.siteId,
          associateCompanyId: selectedCompany.id,
          createdAt: Date.now(),
          targetDate: vdate.getTime(),
        };

        await addDoc(collection(database, "findingList"), new_finding).then(
          (docRef) => {
            new_finding.id = docRef.id;
            useDashboardStore.getState().addFinding(new_finding);
          }
        );

        const findingCat = findingsType.find(x => x.id === itemImpairmentArea);

        const logData = {
          associateCompanyId: selectedCompany.id,
          associateId: userPersonalData.associateId,
          createdBy: userPersonalData.uuid,
          logTitle: "הוספת ממצא בטיחות",
          logDescription: `${userPersonalData.firstName} ${userPersonalData.lastName} יצר ממצא בטיחות חדש עבור ${selectedCompany.companyName}, תיאור הממצא: ${itemImpairmentSeverity}, ${findingCat.safetyReportAreaItem}`
        };

        saveLog(logData);



        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setItemImpairmentTreat('');
        setItemImpairmentTrack('');
        setItemImpairmentArea('');
        setItemImpairmentSeverity('');
        setItemImpairmentLocation('');
        setTargetDate("");
        setItemImpairmentImgReq(false);
        setItemImpairmentCommReq(false);
        setItemImpairmentDesc('');
        setItemImpairmentComments('');
        setDownloadURLs([]);

        // if (modalRef.current) {
        //   const modalInstance = Modal.getInstance(modalRef.current);
        //   if (modalInstance) {
        //     modalInstance.hide();
        //   } else {
        //     new Modal(modalRef.current).hide();
        //   }
        // }

        // setTimeout(() => {
        //   document
        //     .querySelectorAll(".modal-backdrop.fade.show")
        //     .forEach((backdrop) => backdrop.remove());
        //   document.body.classList.remove("modal-open");
        // }, 150);

        setIsLoading(false);

      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    } else {
      toast.error("נא למלא את שדות החובה בדוח");
      setIsLoading(false);
    }
  };

  const uploadFiles = (filesToUpload) => {
    Array.from(filesToUpload).forEach((file) => {
      const storageRef = ref(storage, `findings/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setDownloadURLs((prevURLs) => [...prevURLs, downloadURL]);

            if (file === filesToUpload[filesToUpload.length - 1]) {
              setInputKey(Date.now()); // Update the key to reset the input
            }
          });
        }
      );
    });
  };


  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles); // Set the files in state
    uploadFiles(selectedFiles); // Call uploadFiles immediately
  };


  const handleStatusChanged = async (status, id) => {
    try {
      const findingsRef = doc(database, "findingList", id);
      await updateDoc(findingsRef, {
        status: status
      }).then(res => {
        useDashboardStore.getState().updateFindingClosing(id, {status});
      });
    } catch (error) {
      toast.error(error.message);
    }
  };




  const handleDelete = async (documentId) => {
    const isConfirmed = window.confirm("האם למחוק את ממצא הבטיחות?");
    if (isConfirmed) {
      try {
        await deleteDocument(documentId);
      } catch (error) {
        console.error("Failed to delete the document:", error);
      }
    }
  };
  const deleteDocument = async (documentId) => {
    try {
      await deleteDoc(doc(database, "findingList", documentId));
      useDashboardStore.getState().removeFinding(documentId);
      toast.success("הממצא הוסר מהמערכת");
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };



  const updateFindingItem = async (
    itemImpairmentArea,
    itemImpairmentCommReq,
    itemImpairmentComments,
    itemImpairmentDesc,
    itemImpairmentImgReq,
    itemImpairmentLocation,
    itemImpairmentSeverity,
    itemImpairmentTrack,
    itemImpairmentTreat,
    downloadURLs,
    id,
    targetDate
  ) => {
    try {
      const findingsRef = doc(database, "findingList", id);
      const vdate = new Date(targetDate);
      const updatedData = {
        itemImpairmentArea,
        itemImpairmentCommReq,
        itemImpairmentComments,
        itemImpairmentDesc,
        itemImpairmentImgReq,
        itemImpairmentLocation,
        itemImpairmentSeverity,
        itemImpairmentTrack,
        itemImpairmentTreat,
        gallery: downloadURLs,
        targetDate: vdate.getTime(),
      };
      await updateDoc(findingsRef, updatedData).then(res => {
        useDashboardStore.getState().updateFindingClosing(id, updatedData);
      });

    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateFindingStatus = async (treatComments, treatImages, id) => {
    try {
      const findingsRef = doc(database, "findingList", id);
      const updatedData = {
        status: 'סגור',
        treat: {
          treatComments: treatComments,
          treatImages: treatImages,
          createdAt: Date.now(),
          closedBy: auth.currentUser.uid
        }
      };
      await updateDoc(findingsRef, updatedData).then(res => {
        useDashboardStore.getState().updateFindingClosing(id, updatedData);
      });

    } catch (error) {
      toast.error(error.message);
    }
  };



  const applyFilters = () => {

    let filteredFindings = findings;

    if (selectedCompany) {
      filteredFindings = filteredFindings.filter(report => report.associateCompanyId === selectedCompany.id);
    }
    if (selectedSite) {
      filteredFindings = filteredFindings.filter(report => report.site === selectedSite.siteId);
    }

    
    

    if (statusFilter) {
      filteredFindings = filteredFindings.filter(report => report.status === statusFilter);
    } else {
      filteredFindings = filteredFindings.filter(report => report.status !== 'סגור');
    }

    if (filterArea) {
      filteredFindings = filteredFindings.filter(report => report.itemImpairmentArea === filterArea);
    }

    if (severityFilter) {
      filteredFindings = filteredFindings.filter(report => report.itemImpairmentSeverity === severityFilter);
    }
    if (fromDate) {
      const fromTimestamp = new Date(fromDate).getTime();
      filteredFindings = filteredFindings.filter(report => new Date(report.createdAt).getTime() >= fromTimestamp);
    }
    if (toDate) {
      const toTimestamp = new Date(toDate).getTime();
      filteredFindings = filteredFindings.filter(report => new Date(report.createdAt).getTime() <= toTimestamp);
    }
    setFindingsList(filteredFindings);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedCompany, selectedSite, statusFilter, filterArea, severityFilter, fromDate, toDate, findings]);
  





  const handleDownloadPdf = async () => {
    const element = exportRef.current; // Get the element to export
    const canvas = await html2canvas(element, { scale: 1, useCORS: true }); // Generate canvas from DOM
    const imgData = canvas.toDataURL("image/png"); // Convert canvas to data URL

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190; // A4 width minus some margin
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjusted height based on width
    const pageHeight = pdf.internal.pageSize.height - 20; // A4 height minus some margin for footer

    let heightLeft = imgHeight;
    let position = 0; // Start position for drawing

    // Add the first page with content
    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add subsequent pages if needed
    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft); // Adjust position for next slice
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Add footer on each page
    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8); // Footer font size
      pdf.setTextColor(100); // Footer text color
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        {
          align: "center",
        }
      );
    }

    const timestamp = new Date().getTime();
    pdf.save(`report_${timestamp}.pdf`);
  };
  const handleModal = async () => {
    setShowModal(true); // Open modal to ensure the content is visible for screenshot
  };
  const exportToExcel = (findingsList) => {
    const date = new Date();
    const timestamp = date.getTime(); // This gives you the date as a long number
    const fileName = `Finding_${timestamp}.xlsx`;

    // Define headers in the order you want them to appear (right to left)
    const headers = [
        { header: 'סטטוס', key: 'status', width: 15 },
        { header: 'במעקב', key: 'itemImpairmentTrack', width: 10 },
        { header: 'מנהל בפועל', key: 'itemImpairmentTreat', width: 15 },
        { header: 'חומרת הליקוי', key: 'itemImpairmentSeverity', width: 15 },
        { header: 'מיקום', key: 'itemImpairmentLocation', width: 40 },    
        { header: 'תיאור הממצא', key: 'itemImpairmentDesc', width: 10 },
        { header: 'סוג הממצא', key: 'itemImpairmentArea', width: 10 },
        { header: 'לטיפול עד', key: 'targetDate', width: 15 },
        { header: 'נוצר בתאריך', key: 'createdAt', width: 15 },
        { header: 'מספר מזהה', key: 'id', width: 10 },
    ];

    const data = findingsList.map(report => ({
      id: report.id,
      createdAt: moment(report.createdAt).format('DD/MM/YYYY'),
      targetDate: moment(report.targetDate).format('DD/MM/YYYY'),
      itemImpairmentArea: findingsType.find((x) => x.id === report.itemImpairmentArea)?.safetyReportAreaItem,
      itemImpairmentDesc: report.itemImpairmentDesc,
      itemImpairmentLocation: report.itemImpairmentLocation,
      itemImpairmentSeverity: report.itemImpairmentSeverity,
      itemImpairmentTreat: employees.find((x) => x.id === report.itemImpairmentTreat)?.firstName + ' ' + employees.find((x) => x.id === report.itemImpairmentTreat)?.lastName,
      itemImpairmentTrack: employees.find((x) => x.id === report.itemImpairmentTrack)?.firstName + ' ' + employees.find((x) => x.id === report.itemImpairmentTrack)?.lastName,
      status: report.status,
  }));
  

    // Create a new worksheet and apply the headers
    const ws = XLSX.utils.json_to_sheet(data, { header: headers.map(header => header.key), skipHeader: true });

    // Manually add the headers to the first row
    for (let i = 0; i < headers.length; i++) {
        ws[XLSX.utils.encode_cell({ c: i, r: 0 })] = { v: headers[i].header };
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reports");

    // Write the workbook file
    XLSX.writeFile(wb, fileName);
  };



  

  return (
    <>
    <ToastContainer />
      <div
        className="modal fade"
        id="exampleModal"
        ref={modalRef}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">הוספת ממצא בטיחות חדש</h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-4">
                  <option value="">העלאת תמונה / תמונות</option>
                  <input
                    ref={fileInputRef}
                    key={inputKey}
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                  <div className="row">
                    {downloadURLs.map((url, index) => (
                      <div className="col-lg-1" key={index}>
                        <img
                          src={url}
                          alt={`Uploaded File ${index + 1}`}
                          style={{ width: "70px", height: "auto" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>



                <div className="col-lg-4">
                  <label className="form-label">
                    אחראי לטיפול <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    value={itemImpairmentTreat}
                    onChange={(e) => setItemImpairmentTreat(e.target.value)}
                    className="form-control">
                    <option value="">בחר אחראי לטיפול...</option>
                    {filteredEmployees.length > 0 && (
                      <>
                        {filteredEmployees.map((emp) => (
                          <option key={emp.id} value={emp.id}>
                            {emp?.firstName} {emp?.lastName}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>




                <div className="col-lg-4">
                  <label className="form-label">
                    אחראי למעקב <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    value={itemImpairmentTrack}
                    onChange={(e) => setItemImpairmentTrack(e.target.value)}
                    className="form-control">
                    <option value="">בחר אחראי למעקב...</option>
                    {filteredEmployees.length > 0 && (
                      <>
                        {filteredEmployees.map((emp) => (
                          <option key={emp.id} value={emp.id}>
                            {emp?.firstName} {emp?.lastName}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>




              </div>
              <div className="row">


                <div className="col-lg-4">
                  <label className="form-label">בחר את תחום הממצא <span style={{ color: "red" }}>*</span></label>
                  <select value={itemImpairmentArea} onChange={(e) => setItemImpairmentArea(e.target.value)} className="form-control">
                    <option value="">בחר את תחום הממצא...</option>
                    {findingsType.length > 0 && (
                      <>
                        {findingsType.map((finding) => (
                          <option key={finding.id} value={finding.id}>
                            {finding.safetyReportAreaItem}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>


                <div className="col-lg-4">
                  <label className="form-label">
                    בחר את חומרת הממצא <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                  value={itemImpairmentSeverity}
                    onChange={(e) => setItemImpairmentSeverity(e.target.value)}
                    className="form-control">
                    <option value="">בחר את חומרת הממצא...</option>
                    <option value="קל">קל</option>
                    <option value="בינוני">בינוני</option>
                    <option value="חמור">חמור</option>
                    <option value="מסכן חיים">מסכן חיים</option>
                  </select>
                </div>

                <div className="col-lg-4">
                  <label className="form-label">
                    מיקום הממצא באתר<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={itemImpairmentLocation}
                    onChange={(e) => setItemImpairmentLocation(e.target.value)}
                    type="text"
                    className="form-control" />
                </div>
              </div>

              <div className="row">

              <div className="col-lg-4">
                  <label className="form-label">תאריך יעד לטיפול</label>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(targetDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setTargetDate(e.target.value);
                    }}
                  />
                </div>

                <div className="col-lg-4" style={{paddingTop:40}}>
                  <div class="form-check form-switch">
                    <input
                      onChange={(e) => setItemImpairmentImgReq(e.target.value)}
                      checked={itemImpairmentImgReq}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                    />
                    <label className="form-check-label">
                      לחייב העלאת תמונה בסגירת הדוח
                    </label>
                  </div>
                </div>

                <div className="col-lg-4" style={{paddingTop:40}}>
                  <div class="form-check form-switch">
                    <input
                      onChange={(e) => setItemImpairmentCommReq(e.target.value)}
                      checked={itemImpairmentCommReq}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                    />
                    <label className="form-check-label">
                      לחייב הוספת תגובה בסגירת הדוח
                    </label>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-lg-12">
                  <label className="form-label">
                    תיאור הממצא <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    className="form-control"
                    onChange={(e) => {
                      setItemImpairmentDesc(e.target.value);
                    }}>
                    {itemImpairmentDesc}
                  </textarea>
                </div>
                <div className="col-lg-12">
                  <label className="form-label">הערות לטיפול</label>
                  <textarea
                    className="form-control"
                    onChange={(e) => {
                      setItemImpairmentComments(e.target.value);
                    }}
                  >
                    {itemImpairmentComments}
                  </textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">סגירה</button>

              {
                isLoading ? (<>
                  <div class="spinner-border text-success" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                </>) : (<>
                  <button
                    onClick={addNewFinding}
                    type="button"
                    className="btn btn-success">
                    הוספת ממצא חדש
                  </button>
                </>)
              }

            </div>
          </div>
        </div>
      </div>

      <div className="row heading-container">
        
        <div className="col-lg-9">
          <h3>ממצאי בטיחות | {selectedCompany.companyName} | פרויקט {selectedSite.projectName}</h3>
        </div>
        <div className="col-lg-3 heading-btn-container">
          <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-success btn-lg">הוספת ממצא בטיחות</button>
          {
            myPlan.actions && <>
            <button style={{marginRight:12}} onClick={handleModal} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
            <button className="btn btn-success btn-lg" style={{marginRight:10}} onClick={() => {exportToExcel(findingsList)}}><PiMicrosoftExcelLogoFill color="#ffffff" size={30} /></button>
            </>
          }
          
        </div>
      </div>



      <div className="row heading-container-filter">
        

      <div className="col-lg-2">
          <label className="form-label">תחום הממצא</label>
          <select value={filterArea} onChange={(e) => setFilterArea(e.target.value)} className="form-control">
                    <option value="">בחר את תחום הממצא...</option>
                    {findingsType.length > 0 && (
                      <>
                        {findingsType.map((finding) => (
                          <option key={finding.id} value={finding.id}>
                            {finding.safetyReportAreaItem}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
        </div>




        <div className="col-lg-2">
          <label className="form-label">סטטוס ממצא</label>
          <select
            className="form-control"
            onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="">סינון לפי סטטוס...</option>
            <option value="פעיל">פעיל</option>
            <option value="בטיפול">בטיפול</option>
            <option value="סגור">סגור</option>
          </select>
        </div>



        <div className="col-lg-2">
          <label className="form-label">סינון על פי חומרת הממצא</label>
          <select className="form-control" onChange={(e) => setSeverityFilter(e.target.value)}>
            <option value="">בחר את חומרת הממצא...</option>
            <option value="קל">קל</option>
            <option value="בינוני">בינוני</option>
            <option value="חמור">חמור</option>
            <option value="מסכן חיים">מסכן חיים</option>
          </select>
        </div>



        <div className="col-lg-2">
          <label className="form-label">מתאריך</label>
          <input
            type="date"
            className="form-control"
            value={moment(fromDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
        </div>



        <div className="col-lg-2">
          <label className="form-label">עד תאריך</label>
          <input
            type="date"
            className="form-control"
            value={moment(toDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              updateListByDate(e.target.value);
            }}
          />
        </div>




      </div>


      <div className="row" style={{ padding: 50 }}>
        {Array.isArray(findingsList) &&
          findingsList.map((item) => (
            <FindingItem
              handleStatusChanged={handleStatusChanged}
              updateFindingItem={updateFindingItem}
              companiesArr={companies}
              updateFindingStatus={updateFindingStatus}
              projects={projects}
              findingsType={findingsType}
              employeesArr={filteredEmployees}
              handleDelete={handleDelete}
              item={item}
            />
          ))}
      </div>







      <Modal
        show={showModal}
        dialogClassName="custom-modal"
        onHide={() => setShowModal(false)}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" ref={exportRef} style={{ padding: 50 }}>
            
            <div className="row">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-2">
                    <img src="/images/logo_black.png" style={{ width: 60 }} />
                  </div>
                  <div className="col-lg-10">
                    <p className="companyInfo">
                      דוח בטיחות זה
                      <br />
                      הופק באמצעות
                      <br />
                      מערכת לממונה
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                {myCompany && myCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={myCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{myCompany.companyName}</b>
                          <br />
                          {myCompany.companyAddress}, {myCompany.companyCity}
                          <br />
                          ח.פ. {myCompany.companyTaxId}
                          <br />
                          {myCompany.companyEmail} | {myCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-lg-4">
                {selectedCompany && selectedCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={selectedCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{selectedCompany.companyName}</b>
                          <br />
                          {selectedCompany.companyAddress},{" "}
                          {selectedCompany.companyCity}
                          <br />
                          ח.פ. {selectedCompany.companyTaxId}
                          <br />
                          {selectedCompany.companyEmail} |{" "}
                          {selectedCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 50, marginBottom:50 }}>
              <div className="col-lg-12">
                <h2>ממצאי בטיחות | {selectedCompany.companyName} |{" "}{selectedSite.projectName}</h2>
              </div>
            </div>

            {Array.isArray(findingsList) &&
          findingsList.map((item) => (
            <FindingItemExp
              handleStatusChanged={handleStatusChanged}
              updateFindingItem={updateFindingItem}
              companiesArr={companies}
              updateFindingStatus={updateFindingStatus}
              projects={projects}
              findingsType={findingsType}
              employeesArr={filteredEmployees}
              handleDelete={handleDelete}
              item={item}
            />
          ))}

            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleDownloadPdf}
            className="btn btn-success btn-lg">שמירת קובץ</button>
        </Modal.Footer>
      </Modal>





    </>
  );
};

export default FindingList;
