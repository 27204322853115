import React, { useState, useEffect, useCallback } from "react";
import { auth, database } from "../../services/firebase-config";
import {requestDataByAssociate,getAccountDetails} from "../../services/dbHelper";
import {collection,addDoc,doc,deleteDoc,updateDoc,arrayUnion} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import SafetyReportItem from "./components/SafetyReportItem";
import SafetyReportSubItem from "./components/SafetyReportSubItem";
import SafetyReportSubItemScore from "./components/SafetyReportSubItemScore";
import useDashboardStore from "../../store/useDashboardStore";
import { requestSuperData } from '../../store/dashboardHook';
import SafetyReportItemAdmin from "./components/SafetyReportItemAdmin";
import { FaQuestionCircle } from "react-icons/fa";


const SafetyReportsItems = () => {
  const { userPersonalData } = useDashboardStore();
  const [safetyReportItem, setSafetyReportItem] = useState("");
  const [safetyReportSubItem, setSafetyReportSubItem] = useState("");
  const [safetyReportSubItemTitleScore, setSafetyReportSubItemTitleScore] = useState("");
  const [safetyReportSubItemTitleName, setSafetyReportSubItemTitleName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [myAccount, setMyAccount] = useState(false);
  const [adminCategory, setAdminCategory] = useState(false);
  const [safetyReportsMain, setSafetyReportsMain] = useState([]);
  const [safetyReportsMainAdmin, setSafetyReportsMainAdmin] = useState([]);
  const [selectedSafetyReportItem, setSelectedSafetyReportItem] = useState(null);
  const [selectedSafetyReportSubItem, setSelectedSafetyReportSubItem] = useState(null);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const account = await getAccountDetails(auth.currentUser.uid);

      const userSafetyReportsItemArr = await requestDataByAssociate(account.associateId,"safetyReportsItems");
      const adminSafetyReportsItemArr = await requestSuperData("safetyReportsItems");

      const userSafetyReportsItems = userSafetyReportsItemArr.filter(x => x.adminCategory === false);
      const adminSafetyReportsItems = adminSafetyReportsItemArr.filter(x => x.adminCategory === true);

      setSafetyReportsMain(userSafetyReportsItems);
      setSafetyReportsMainAdmin(adminSafetyReportsItems);

      setMyAccount(account);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const addSafetyReportItem = async () => {
    if (safetyReportItem !== "") {
      try {
        await addDoc(collection(database, "safetyReportsItems"), {
          associateId: myAccount.associateId,
          safetyReportItem: safetyReportItem,
          adminCategory: adminCategory,
          subCategories: [],
          templateId: ''
        });
        loadData();
        setSafetyReportItem("");
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא למלא את שם סוג הדוח");
    }
  };

  const addSafetyReportSubItem = async () => {
    if (safetyReportSubItem !== "" && selectedSafetyReportItem) {
      try {
        const newSubItem = {
          itemId: generateRandomId(),
          safetyReportSubItem: safetyReportSubItem,
          sections: [],
        };

        const safetyReportsRef = doc(
          database,
          "safetyReportsItems",
          selectedSafetyReportItem.id
        );
        await updateDoc(safetyReportsRef, {
          subCategories: arrayUnion(newSubItem),
        });

        setSelectedSafetyReportItem((prevItem) => {
          return {
            ...prevItem,
            subCategories: [...prevItem.subCategories, newSubItem],
          };
        });
        loadData();
        toast.success("הקטגוריה נשמרה בהצלחה");
        setSafetyReportSubItem("");
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא למלא את שם הקטגוריה הראשית");
    }
  };

  const addSafetyScore = async () => {
    if (
      selectedSafetyReportItem &&
      selectedSafetyReportSubItem &&
      safetyReportSubItemTitleName !== "" &&
      safetyReportSubItemTitleScore !== ""
    ) {
      try {

        console.log("selectedSafetyReportItem.id: " + selectedSafetyReportItem.id);

        const newScoreItem = {
          itemId: generateRandomId(),
          safetyReportSubItemTitleName: safetyReportSubItemTitleName,
          safetyReportSubItemTitleScore: safetyReportSubItemTitleScore,
        };


        const safetyReportsRef = doc(
          database,
          "safetyReportsItems",
          selectedSafetyReportItem.id
        );

        // Update the state using functional updates
        setSelectedSafetyReportItem((prevItem) => {
          const subItemIndex = prevItem.subCategories.findIndex(
            (subItem) => subItem.itemId === selectedSafetyReportSubItem.itemId
          );

          const updatedSubCategories = [...prevItem.subCategories];
          const updatedSections = [
            ...(updatedSubCategories[subItemIndex].sections || []),
            newScoreItem,
          ];

          updatedSubCategories[subItemIndex] = {
            ...updatedSubCategories[subItemIndex],
            sections: updatedSections,
          };

          return {
            ...prevItem,
            subCategories: updatedSubCategories,
          };
        });

        setSelectedSafetyReportSubItem((prevSubItem) => {
          return {
            ...prevSubItem,
            sections: [...(prevSubItem.sections || []), newScoreItem],
          };
        });

        // Update the document with the new array
        await updateDoc(safetyReportsRef, {
          subCategories: selectedSafetyReportItem.subCategories,
        });
        loadData();
        toast.success("הקטגוריה נשמרה בהצלחה");
        setSafetyReportSubItemTitleName("");
        setSafetyReportSubItemTitleScore("");
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא למלא את שם הקטגוריה המשנית ואת הניקוד לקטגוריה");
    }
  };

  function generateRandomId() {
    return Math.random().toString(36).substr(2, 10);
  }

  const selectAndClear = (item) => {
    setSelectedSafetyReportSubItem(null)
    setSelectedSafetyReportItem(item)
  }

  const removeReportItem = async(id) => {
    const isConfirmed = window.confirm("האם להסיר את הקטגוריה מהמערכת?");
    if(isConfirmed){
        try {
            await deleteDoc(doc(database, "safetyReportsItems", id))
            .then((res) => {
                loadData();
                toast.success("הקטגוריה הוסרה בהצלחה");
              }).catch((err) => {
                toast.error(err.message);
              });
        } catch (error) {
            toast.error(error.message)
        }
    }
  }

  const updateReportItem = async(id,safetyReportItem,adminCategory) => {
      try {
        const updateData = {
          safetyReportItem,
          adminCategory};
            const safetyReportRef = doc(database, "safetyReportsItems", id);
            await updateDoc(safetyReportRef, {
                ...updateData
              }).then(() => {
                loadData()
                toast.success('הקטגוריה עודכנה בהצלחה');
              });
    } catch (error) {
        toast.error(error.message);
    }
  }

  const removeSafetyReportSubItem = async (subItemId) => {
    const isConfirmed = window.confirm("האם להסיר את הקטגוריה מהמערכת?");
    if(isConfirmed){
      const updatedSubCategories = selectedSafetyReportItem.subCategories.filter(sub => sub.itemId !== subItemId);
      try {
        const safetyReportsRef = doc(database, "safetyReportsItems", selectedSafetyReportItem.id);
        await updateDoc(safetyReportsRef, {
          subCategories: updatedSubCategories
        });
        setSelectedSafetyReportItem({...selectedSafetyReportItem, subCategories: updatedSubCategories});
        loadData();
        toast.success("הקטגוריה הוסרה בהצלחה");
      } catch (error) {
        console.error(error.message);
        toast.error("הסרת הקטגוריה נכשלה");
      }
    }
  };

  const updateSafetyReportSubItem = async(subItemId,newSubItemDetails) => {
    const updatedSubCategories = selectedSafetyReportItem.subCategories.map(sub => {
      if (sub.itemId === subItemId) {
        return { ...sub, ...newSubItemDetails };
      }
      return sub;
    });
  
    try {
      const safetyReportsRef = doc(database, "safetyReportsItems", selectedSafetyReportItem.id);
      await updateDoc(safetyReportsRef, {
        subCategories: updatedSubCategories
      });
      setSelectedSafetyReportItem({...selectedSafetyReportItem, subCategories: updatedSubCategories});
      toast.success("הקטגוריה עודכנה בהצלחה");
      loadData();
    } catch (error) {
      console.error(error.message);
      toast.error("עדכון קטגוריה נכשלה");
    }
  }

  const addTemplateToMyList = async(item) => {
    try {
      await addDoc(collection(database, "safetyReportsItems"), {
        associateId: myAccount.associateId,
        safetyReportItem: item.safetyReportItem,
        adminCategory: false,
        subCategories: item.subCategories,
        templateId: item.id
      });
      loadData();
    } catch (error) {
      toast.error(error.message);
    }
  }

  const [showTooltip1, setShowTooltip1] = useState(false);
  const toggleTooltip1 = () => setShowTooltip1(!showTooltip1);

  const [showTooltip2, setShowTooltip2] = useState(false);
  const toggleTooltip2 = () => setShowTooltip2(!showTooltip2);

  const [showTooltip3, setShowTooltip3] = useState(false);
  const toggleTooltip3 = () => setShowTooltip3(!showTooltip3);


  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>הגדרות ממצאי בטיחות</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>



        <div className="col-lg-3">
          <h6>שם הדו״ח <FaQuestionCircle onClick={toggleTooltip1} style={{ cursor: 'pointer' }} size={20} color="#0e6da8" />
          {showTooltip1 && (
            <div style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background:"#ffcc00" }}>
              <p className="tooltip-text">
              בשדה זה תוכלו למלא את שם הדוח.<br/>
              השם הנו לצורך נוחות וישמש אתכם כאשר <br/>
              תרצו להוסיף מבדק בטיחות חדש <br/>
              באמצעות דוחות בטיחות

              </p>
            </div>
          )}
          </h6> 


          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-9">
              <input
                value={safetyReportItem}
                onChange={(e) => setSafetyReportItem(e.target.value)}
                type="text"
                placeholder="שם סוג דוח בטיחות"
                className="form-control"
              />
            </div>
            <div className="col-lg-3">
              <button onClick={addSafetyReportItem} className="btn btn-success">הוספה</button>
            </div>
            <div className="col-lg-12">
              {
                userPersonalData.role === 'superadmin' && (<>
                <input
                    onChange={(e) => setAdminCategory(e.target.checked)}
                    checked={adminCategory}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"/>{" "}<label className="form-check-label">הוסף קטגוריה לכל המשתמשים</label>
                </>)
              }
            </div>
          </div>



          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12">
              {isLoading ? (
                <>
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  {safetyReportsMain.length > 0 ? (
                    <>
                      <p style={{ marginBottom: 12 }}><b>הקטגוריות שלי</b></p>
                      {safetyReportsMain.map((item) => (
                        <SafetyReportItem
                          key={item.id}
                          selectedSafetyReportItem={selectedSafetyReportItem}
                          item={item}
                          selectAndClear={selectAndClear}
                          removeReportItem={removeReportItem}
                          updateReportItem={updateReportItem}
                        />
                      ))}
                    </>
                  ) : (
                    <p>לא קיימות קטגוריות במערכת</p>
                  )}

                  <p style={{ marginBottom: 12, marginTop: 12 }}><b>קטגוריות מערכת</b></p>
                  {safetyReportsMainAdmin.filter(adminItem => !safetyReportsMain.some(myItem => myItem.templateId === adminItem.id)).map((item) => (
                    <SafetyReportItemAdmin
                      role={userPersonalData.role}
                      addTemplateToMyList={addTemplateToMyList}
                      key={item.id}
                      selectedSafetyReportItem={selectedSafetyReportItem}
                      item={item}
                      selectAndClear={selectAndClear}
                      removeReportItem={removeReportItem}
                      updateReportItem={updateReportItem}
                    />
                  ))}
                </>

              )}
            </div>
          </div>
        </div>




        <div className="col-lg-1"></div>

        <div className="col-lg-3">
          <h6>סעיפים ראשיים בדו״ח <FaQuestionCircle onClick={toggleTooltip2} style={{ cursor: 'pointer' }} size={20} color="#0e6da8" />
          {showTooltip2 && (
            <div style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background: '#ffcc00' }}>
              <p className="tooltip-text">
                הסעיפים הראשיים בדו״ח הם למעשה קטגוריות.<br/>
                ניתן להוסיף קטגוריות ללא הגבלה<br/>
                והציון הסופי של הדו״ח הוא למעשה שקלול ממוצע<br/>
                של כל הקטגוריות.
              </p>
            </div>
          )}</h6>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-9">
              <input
                value={safetyReportSubItem}
                onChange={(e) => setSafetyReportSubItem(e.target.value)}
                type="text"
                placeholder="קטגורייה ראשית בדו״ח"
                className="form-control"
              />
            </div>
            <div className="col-lg-2">
              <button
                onClick={addSafetyReportSubItem}
                className="btn btn-success">הוספה</button>
            </div>
          </div>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12">
              {selectedSafetyReportItem ? (
                <>
                  {selectedSafetyReportItem.subCategories.length > 0 ? (
                    <>
                      {selectedSafetyReportItem.subCategories.map((subItem) => (
                        <>
                          <SafetyReportSubItem
                            selectedSafetyReportSubItem={selectedSafetyReportSubItem}
                            setSelectedSafetyReportSubItem={setSelectedSafetyReportSubItem}
                            subItem={subItem}
                            removeSafetyReportSubItem={removeSafetyReportSubItem}
                            updateSafetyReportSubItem={updateSafetyReportSubItem}
                          />
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <p>לא קיימות קטגוריות ראשיות בדו״ח זה</p>
                    </>
                  )}
                </>
              ) : (
                <p>נא לבחור את סוג הדוח מהרשימה</p>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-1"></div>

        <div className="col-lg-4">
          <h6>סעיפים משניים בדו״ח <FaQuestionCircle onClick={toggleTooltip3} style={{ cursor: 'pointer' }} size={20} color="#0e6da8" />
          {showTooltip3 && (
            <div style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background: '#ffcc00' }}>
              <p className="tooltip-text">
                סעיפים משניים הם למעשה קטגוריות משנה.<br/>
                לכל קטגוריה ניתן להוסיף קטגוריות משנה ללא הגבלה<br/>
                לכל קטגוריית משנה יש לצרף נקודות ציון כך<br/>
                שסך כל הנקודות יגיעו לציון 100<br/>
                בשורת הכותרת למעלה תוכלו להעזר בסיכום הניקוד<br/>
                אשר מציג לכם בכל רגע את סה״כ הניקוד שהתווסף
              </p>
            </div>
          )}

          {selectedSafetyReportSubItem && (
            <>
              {selectedSafetyReportSubItem.sections.length > 0 && (
                <>
                  <div>100 / {
                    selectedSafetyReportSubItem.sections.reduce((total, current) => {
                      return total + parseInt(current.safetyReportSubItemTitleScore, 10);
                    }, 0)
                  }</div>
                </>
              )}
            </>
          )}</h6>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-7">
              <input
                value={safetyReportSubItemTitleName}
                onChange={(e) =>
                  setSafetyReportSubItemTitleName(e.target.value)
                }
                type="text"
                placeholder="סעיפים משניים בדו״ח"
                className="form-control"
              />
            </div>
            <div className="col-lg-2">
              <input
                value={safetyReportSubItemTitleScore}
                onChange={(e) =>
                  setSafetyReportSubItemTitleScore(e.target.value)
                }
                type="text"
                placeholder="ניקוד"
                className="form-control"
              />
            </div>
            <div className="col-lg-2">
              <button onClick={addSafetyScore} className="btn btn-success">
                הוספה
              </button>
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12">
              {selectedSafetyReportSubItem ? (
                <>
                  {selectedSafetyReportSubItem.sections.length > 0 ? (
                    <>
                      {selectedSafetyReportSubItem.sections.map((score) => (
                        <SafetyReportSubItemScore score={score} />
                      ))}
                    </>
                  ) : (
                    <>
                      <p>לא קיימות קטגוריות משנה בדו״ח זה</p>
                    </>
                  )}
                </>
              ) : (
                <p>נא לבחור את הקטגוריה הראשית מהרשימה</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SafetyReportsItems;
