import React, { useState, useEffect,useRef } from "react";
import useDashboardStore from "../../store/useDashboardStore";
import UseDashboardAction from "../../store/UseDashboardActions";
import { ToastContainer, toast } from "react-toastify";
import Boxes from "./components/Boxes";
import LogCard from "../../components/LogCard";
import HpFinding from "../../components/HpFinding";
import NotifyCommittee from "../../components/NotifyCommittee";
import NotifyLicense from "../../components/NotifyLicense";
import TrainingNotify from "../../components/TrainingNotify";
import NotifyMachine from "../../components/NotifyMachine";
import { Modal, Button, Carousel } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FaRegFilePdf } from "react-icons/fa";
import BoxesFlat from "./components/BoxesFlat";
import HpFindingRow from "../../components/HpFindingRow";
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import { FaCircleCheck,FaCircleXmark } from "react-icons/fa6";


const Dashboard = (props) => {
  const exportRef = React.useRef();
  const [showModalforPdf, setShowModalforPdf] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [companyEmployees, setCompanyEmployyes] = useState([]);
  const [companySafetyReports, setCompanySafetyReports] = useState([]);
  const [companySafetyTests, setCompanySafetyTests] = useState([]);
  const [companyFindings, setCompanyFindings] = useState([]);
  const [companyCommittees, setCompanyComittees] = useState([]);
  const [companyMachinesReports, setCompanyMachinesReports] = useState([]);
  const [companyTrainings, setCompanyTrainings] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [filteredFindings, setFilteredFindings] = useState([]);
  const [filteredCommittees, setFilteredCommittees] = useState([]);
  const [filteredMachinesReports, setFilteredMachinesReports] = useState([]);
  const [filteredSafetyTests, setFilteredSafetyTests] = useState([]);
  const [filteredTrainingGroups, setFilteredTrainingGroups] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [allMachines, setAllMachines] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activePopups, setActivePopups] = useState([]);
  const [index, setIndex] = useState(0);


  const navigate = useNavigate();

  // Use inside a function
  const handleNavigate = (link2url) => {
    if (link2url) {
      navigate(link2url);
    }
  };



  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('./Animation - 1720070201369.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };



  const {
    mainAccount,
    userPersonalData,
    committees,
    employees,
    isLoading,
    companies,
    myCompany,
    selectedCompany,
    selectedSite,
    setSelectedCompany,
    safetyTests,
    findings,
    machineReports,
    safetyReports,
    safetyReportsAreas,
    trainingGroups,
    setSelectedSite,
    logs,
    popups,
    myPlan,
    accounts
  } = useDashboardStore();

  useEffect(() => {
    const filteredPopups = popups.filter(x => x.isActive && x.isWebDisplay);
    if (filteredPopups.length > 0) {
        setActivePopups(filteredPopups);
        setShowModal(true);
    }
}, [popups]);

const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
};

const currentDate = new Date().getTime(); // Current time in milliseconds
const isAccountActive = mainAccount.expireDate > currentDate;


const handleDownloadPdf = async () => {
  const element = exportRef.current; // Get the element to export
  const canvas = await html2canvas(element, { scale: 1, useCORS: true }); // Generate canvas from DOM
  const imgData = canvas.toDataURL("image/png"); // Convert canvas to data URL

  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  const imgWidth = 190; // A4 width minus some margin
  const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjusted height based on width
  const pageHeight = pdf.internal.pageSize.height - 20; // A4 height minus some margin for footer

  let heightLeft = imgHeight;
  let position = 0; // Start position for drawing

  // Add the first page with content
  pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  // Add subsequent pages if needed
  while (heightLeft > 0) {
    position = -(imgHeight - heightLeft); // Adjust position for next slice
    pdf.addPage();
    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }

  // Add footer on each page
  const numPages = pdf.internal.getNumberOfPages();
  for (let i = 1; i <= numPages; i++) {
    pdf.setPage(i);
    pdf.setFontSize(8); // Footer font size
    pdf.setTextColor(100); // Footer text color
    pdf.text(
      "This report was generated with Lamemone ERP",
      105,
      pdf.internal.pageSize.height - 10,
      {
        align: "center",
      }
    );
  }

  const timestamp = new Date().getTime();
  pdf.save(`dashboard${timestamp}.pdf`);
};






const handleModalforPdf = async () => {
  setShowModalforPdf(true); // Open modal to ensure the content is visible for screenshot
};



  useEffect(() => {
    const savedCompany = localStorage.getItem("selectedCompany");
    const savedSite = localStorage.getItem("selectedSite");
    if (savedCompany) {
      const companyInfo = companies.find((x) => x.id === savedCompany);
      setSelectedCompany(companyInfo);
      setSitesList(companyInfo?.projects || []);
    }
    if (savedSite) {
      const site_details = companies
        .flatMap((company) => company.projects)
        .find((project) => project.siteId === savedSite);
      setSelectedSite(site_details);
    }
  }, [companies, setSelectedCompany, setSelectedSite]);


  useEffect(() => {
    if (userPersonalData.role === "user" && userPersonalData.projectsRole) {
      if (userPersonalData.projectsRole.length > 0) {
        const rolesSet = new Set(
          userPersonalData.projectsRole.map((roleItem) => roleItem.role)
        );
        const filteredCompanies = companies
        .filter((company) => rolesSet.has(company.id))
        .sort((a, b) => a.companyName.localeCompare(b.companyName));

        setCompaniesList(filteredCompanies);
      }
    } else if (userPersonalData.role === "admin" || userPersonalData.role === "superadmin") {
      const filteredCompanies = companies
        .sort((a, b) => a.companyName.localeCompare(b.companyName));
        setCompaniesList(filteredCompanies);
      }
  }, [userPersonalData, companies]);


  useEffect(() => {
    if (userPersonalData) {
      if (selectedCompany) {
        const _findings = findings.filter(
          (x) => x.associateCompanyId === selectedCompany.id
        );
        const _machines_reports = machineReports.filter(
          (x) => x.associateCompanyId === selectedCompany.id && x.isActive
        );
        const _committees = committees.filter(
          (x) => x.associateCompanyId === selectedCompany.id
        );
        const _employees = employees.filter(
          (x) => x.associateCompany === selectedCompany.id
        );
        const _trainings = trainingGroups.filter(
          (x) => x.associateCompanyId === selectedCompany.id
        );
        const _safetyReports = safetyReports.filter(
          (x) => x.associateCompanyId === selectedCompany.id
        );
        const _safetyTests = safetyTests.filter(
          (x) => x.associateCompanyId === selectedCompany.id
        )
        setCompanyComittees(_committees);
        setCompanyEmployyes(_employees);
        setCompanyFindings(_findings);
        setCompanyMachinesReports(_machines_reports);
        setCompanySafetyReports(_safetyReports);
        setCompanyTrainings(_trainings);
        setCompanySafetyTests(_safetyTests)
      } else {
        setSitesList([]);
        setCompanyComittees([]);
        setCompanyEmployyes([]);
        setCompanyFindings([]);
        setCompanyMachinesReports([]); 
        setCompanySafetyReports([]);
        setCompanySafetyTests([]);
        setCompanyTrainings([]);
        setFilteredEmployees([]);
        setFilteredFindings([]);
        setFilteredCommittees([]);
        setFilteredMachinesReports([]);
        setFilteredSafetyTests([]);
        setFilteredTrainingGroups([]);
      }
    }
  }, [
    selectedCompany,
    userPersonalData,
    findings,
    machineReports,
    committees,
    employees,
    trainingGroups,
    safetyReports,
  ]);


  const handleCompanySelect = (company) => {
    if (company === "") {
      setSelectedCompany(null);
      localStorage.removeItem("selectedCompany");
      localStorage.removeItem("selectedSite");
      setSelectedSite(null);
      setSitesList([]); // Clear sites list when no company is selected
    } else {
      const companyInfo = companies.find((x) => x.id === company);
      setSelectedCompany(companyInfo);
      localStorage.setItem("selectedCompany", company);
  
      // Always reset sites list, even if there are no projects
      const filteredSites = companyInfo.projects
        ? companyInfo.projects.sort((a, b) => a.projectName.localeCompare(b.projectName))
        : [];
      setSitesList(filteredSites);
  
      if (filteredSites.length === 0) {
        // No projects, so ensure no site is selected
        setSelectedSite(null);
        localStorage.removeItem("selectedSite");
      }
    }
  };

  const handleSiteSelect = (site) => {
    if (site === "") {
        setSelectedSite(null);
        localStorage.removeItem("selectedSite");
    } else {
        const site_details = selectedCompany.projects.find(
            (x) => x.siteId === site
        );
        setSelectedSite(site_details);
        localStorage.setItem("selectedSite", site);
        console.log("Selected Site Updated", site_details);
    }
};

  useEffect(() => {
    if (selectedCompany) {
      const currentDate = new Date();

      const logsByCompany = logs
        .filter((x) => x.associateCompanyId === selectedCompany.id)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, 10);
      setFilteredLogs(logsByCompany);

      const companyProjects = selectedCompany.projects;
      setAllProjects(companyProjects);

      const companyMachines = selectedCompany.machines;
      setAllMachines(companyMachines);

      const detailedFilesArray = employees
        .filter((emp) => emp.associateCompany === selectedCompany.id)
        .flatMap((emp) => {
          return emp.files
            .map((file) => {
              const fileExpDate = new Date(file.fileExpDate);
              const timeDiff = fileExpDate - currentDate;
              const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
              return {
                id: emp.id,
                firstName: emp.firstName,
                lastName: emp.lastName,
                createdAt: emp.createdAt,
                employeeId: emp.employeeId,
                fileName: file.fileName,
                fileExpDate: file.fileExpDate,
                daysLeft: daysLeft,
              };
            })
            .filter((file) => file.daysLeft >= 0 && file.daysLeft <= 30);
        });
      setFilteredEmployees(detailedFilesArray);

      const filteredAndEnhancedFindings = findings
        .filter(
          (group) =>
            group.associateCompanyId === selectedCompany.id &&
            group.site === selectedSite?.siteId &&
            group.status !== "סגור" &&
            new Date(group.targetDate).getTime() <=
              currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
        )
        .map((group) => ({
          ...group,
          daysLeft: Math.ceil(
            (new Date(group.targetDate) - currentDate) / (1000 * 60 * 60 * 24)
          ),
        }));
      setFilteredFindings(filteredAndEnhancedFindings);



      const filteredAndEnhancedCommittees = committees
        .filter(
          (group) =>
            group.associateCompanyId === selectedCompany.id &&
            new Date(group.committeeNextDate).getTime() <=
              currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
        )
        .map((group) => ({
          ...group,
          daysLeft: Math.ceil(
            (new Date(group.committeeNextDate) - currentDate) /
              (1000 * 60 * 60 * 24)
          ),
        }));
      setFilteredCommittees(filteredAndEnhancedCommittees);



      const filteredAndEnhancedMachineReports = machineReports
        .filter(
          (group) =>
            group.associateCompanyId === selectedCompany.id &&
            new Date(group.nextReportDate).getTime() <=
              currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
        )
        .map((group) => ({
          ...group,
          daysLeft: Math.ceil(
            (new Date(group.nextReportDate) - currentDate) /
              (1000 * 60 * 60 * 24)
          ),
        }));
      setFilteredMachinesReports(filteredAndEnhancedMachineReports);



      const filteredAndEnhancedSafetyTests = safetyTests
        .filter(group => 
            group.associateCompanyId === selectedCompany.id && 
            new Date(group.trainingNextDate).getTime() <= new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)).getTime()
        )
        .map(group => {
            const trainingNextDate = new Date(group.trainingNextDate);
            const timeDiff = trainingNextDate - currentDate;
            const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  
            return {
                ...group,
                daysLeft
            };
        });
        setFilteredSafetyTests(filteredAndEnhancedSafetyTests);



      const filteredAndEnhancedTrainingGroups = trainingGroups
          .filter(group => 
              group.associateCompanyId === selectedCompany.id && 
              new Date(group.trainingNextDate).getTime() <= new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)).getTime()
          )
          .map(group => {
              const trainingNextDate = new Date(group.trainingNextDate);
              const timeDiff = trainingNextDate - currentDate;
              const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  
              return {
                  ...group,
                  daysLeft
              };
          });
          setFilteredTrainingGroups(filteredAndEnhancedTrainingGroups);

    }
  }, [
    selectedCompany,
    employees,
    findings,
    machineReports,
    safetyTests,
    selectedSite,
    committees,
    trainingGroups,
    logs,
  ]);


  const [countCompanies, setCountCompanies] = useState([]);
  const [countSites, setCountSites] = useState(0);
  const [countEmployees, setCountEmployees] = useState([]);
  useEffect(() => {
    
    const allcompanies = companies.filter(x => x.associateId === mainAccount.associateId && x.isArchive !== true);
    setCountCompanies(allcompanies);

    const totalSites = companies.reduce((total, company) => {
      return total + company.projects.length;
    }, 0);
    setCountSites(totalSites);

  },[])


  return (
    <>
    <UseDashboardAction>
      {
        isAccountActive ? (<>
            
      <ToastContainer />
      <Modal show={showModal} onHide={() => setShowModal(false)} size="md">
            <Modal.Header closeButton>
                <Modal.Title>עדכוני מערכת ({activePopups.length})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
                    {activePopups.map((popup, idx) => (
                        <Carousel.Item key={idx}>
                            <p style={{fontSize:22}}>{popup.popupTitle}</p>
                            <p style={{fontSize:19}}>{popup.popupContent}</p>
                            {popup.imageUrl && <img src={popup.imageUrl} alt="Alert" className="d-block w-100" />}
                            <br/>
                            {
                              selectedCompany && selectedSite && (<>
                                {
                                  popup.link !== '' && (<>
                                  <button className="btn btn-warning btn-lg" onClick={() => {handleNavigate(popup.link)}}>עבור לעמוד</button>
                                  </>)
                                }
                              </>)
                            }
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => setShowModal(false)}>סגירה</Button>
            </Modal.Footer>
      </Modal>

      <div className="row heading-container">
        <div className="col-lg-11"><h3>ראשי</h3></div>
        <div className="col-lg-1">
          {
            selectedCompany && selectedSite && myPlan.actions && <button style={{marginRight:12}} onClick={handleModalforPdf} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
          }
        </div>
      </div>

      {isLoading ? (
        <div className="row" style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 14 }}>

          <Lottie options={defaultOptions}
              height={400}
              width={400}
              isStopped={false}
              isPaused={false}/>
        </div>
      ) : (
        <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 14 }}>

          <div className="row">
            <div className="col-lg-2 planCounter">
              <p>מסלול קיים:  {myPlan.planName}</p>
            </div>
            <div className="col-lg-2 planCounter">
              <p>חברות פעילות: {countCompanies.length} מתוך {myPlan.companies}</p>
            </div>
            <div className="col-lg-2 planCounter">
              <p>אתרים פעילים: {countSites} מתוך {myPlan.sites}</p>
            </div>
            <div className="col-lg-2 planCounter">
              <p>יוזרים: {accounts.length} מתוך {myPlan.users}</p>
            </div>
            <div className="col-lg-2 planCounter">
              <p>עובדים: {employees.length} מתוך {myPlan.employees}</p>
            </div>
            <div className="col-lg-2 planCounter">
              <p>ייצוא/ייבוא מסמכים: {myPlan.actions ? <FaCircleCheck color="#00BD9D" size={20} /> : <FaCircleXmark color="#9b0952" size={20} />}</p>
            </div>
          </div>


            {selectedCompany ? (
              <>
              <Boxes
                selectedSite={selectedSite}
                companyCommittees={companyCommittees}
                companyFindings={companyFindings}
                companyMachinesReports={companyMachinesReports}
                companySafetyReports={companySafetyReports}
                companyTrainings={companyTrainings}
                selectedCompany={selectedCompany}
                companyEmployees={companyEmployees}
                companySafetyTests={companySafetyTests}
              />
              </>
            ) : (
              <p>נא לבחור את שם החברה מהרשימה</p>
            )}
            <div className="row mtop">

              <div className="col-lg-6">
                <select onChange={(e) => handleCompanySelect(e.target.value)} className="form-select" value={selectedCompany?.id || ""}>
                  <option value="">בחר חברה מהרשימה...</option>
                  {companiesList.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-lg-6">
                <select onChange={(e) => handleSiteSelect(e.target.value)} className="form-select" value={selectedSite?.siteId || ""}>
                  <option value="">בחר פרויקט מהרשימה...</option>
                  {sitesList.map((site) => (
                    <option key={site.siteId} value={site.siteId}>
                      {site.projectName}
                    </option>
                  ))}
                </select>
              </div>

            </div>

            {
              selectedCompany && selectedSite && (<>

              <div className="row mtop">
                <div className="col-lg-6">

                  <div className="db-heading"><p className="db-heading-title">התראות</p></div>

                <div className="logs-scroll-box">

                  <div className="notification-dashboard-title">התראות ועדות בטיחות</div>
                  
                  {filteredCommittees.length > 0 ? (
                    <>
                      {filteredCommittees.map((item) => (
                        <>
                          <NotifyCommittee type="committee" item={item} />
                        </>
                      ))}
                    </>
                  ) : (
                    <p>לא קיימות התראות פעילות</p>
                  )}
                  <div className="notification-dashboard-title">
                    התראות עובדים ורישיונות
                  </div>
                  {filteredEmployees.length > 0 ? (
                    <>
                      {filteredEmployees.map((item) => (
                        <>
                          <NotifyLicense item={item} type="license" />
                        </>
                      ))}
                    </>
                  ) : (
                    <p>לא קיימות התראות פעילות</p>
                  )}
                  <div className="notification-dashboard-title">
                    התראות הדרכות
                  </div>

                  {filteredTrainingGroups.length > 0 ? (
                    <>
                      {filteredTrainingGroups.map((item) => (
                        <>
                          <TrainingNotify item={item} />
                        </>
                      ))}
                    </>
                  ) : (
                    <p>לא קיימות התראות פעילות</p>
                  )}

                  <div className="notification-dashboard-title">
                    התראות תסקירים תקופתיים
                  </div>
                  {filteredMachinesReports.length > 0 ? (
                    <>
                      {filteredMachinesReports.map((item) => (
                        <>
                          <NotifyMachine
                            allMachines={allMachines}
                            item={item}
                            type="machine"
                          />
                        </>
                      ))}
                    </>
                  ) : (
                    <p>לא קיימות התראות פעילות</p>
                  )}
                </div>
              </div>


              <div className="col-lg-4">
                <div className="db-heading">
                  <p className="db-heading-title">ממצאים פתוחים</p>
                </div>
                <div className="logs-scroll-box">
                  {filteredFindings.length > 0 && (
                    <>
                      {filteredFindings.map((item) => (
                        <>
                          <HpFinding
                            findingsType={safetyReportsAreas}
                            projects={allProjects}
                            companiesArray={companies}
                            employeesArr={employees}
                            item={item}
                          />
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <div className="col-lg-2">
                <div className="db-heading">
                  <p className="db-heading-title">פעולות אחרונות במערכת</p>
                </div>
                <div className="logs-scroll-box">
                  {filteredLogs.length > 0 && (
                    <>
                      {filteredLogs.map((log) => (
                        <>
                          <LogCard log={log} />
                        </>
                      ))}
                    </>
                  )}
                </div>
              </div>
              </div>
              </>)
            }
          </div>

      )}
        </>) : (<>
          <div style={{padding:100}}>
          <p>המסלול החודשי הסתיים אנא הכנס להגדרות - פרטי החברה שלי - על מנת לחדש את המנוי</p>
          </div>
        </>)
      }


        <Modal
          show={showModalforPdf}
          onHide={() => setShowModalforPdf(false)}
          dialogClassName="custom-modal"
          size="lg">

          <Modal.Header closeButton>
            <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <div ref={exportRef} style={{ padding: 50 }}>

            <div className="row" style={{ padding: 50 }}>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-2">
                    <img src="/images/logo_black.png" style={{ width: 60 }} alt="Logo" />
                  </div>
                  <div className="col-lg-10">
                    <p className="companyInfo">דוח בטיחות זה<br/>הופק באמצעות<br/>מערכת לממונה</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                {myCompany && (
                  <div className="row">
                    <div className="col-lg-3">
                      <img src={myCompany.companyLogo || '/images/default_logo.png'} style={{ width: 80 }} alt="Company Logo"/>
                    </div>
                    <div className="col-lg-9">
                      <p className="companyInfo">
                        <b>{myCompany.companyName}</b><br/>
                        {myCompany.companyAddress}, {myCompany.companyCity}<br/>
                        ח.פ. {myCompany.companyTaxId}<br/>
                        {myCompany.companyEmail} | {myCompany.companyPhone}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                {selectedCompany && (
                  <div className="row">
                    <div className="col-lg-3">
                      <img src={selectedCompany.companyLogo || '/images/default_logo.png'} style={{ width: 80 }} alt="Selected Company Logo"/>
                    </div>
                    <div className="col-lg-9">
                      <p className="companyInfo">
                        <b>{selectedCompany.companyName}</b><br/>
                        {selectedCompany.companyAddress}, {selectedCompany.companyCity}<br/>
                        ח.פ. {selectedCompany.companyTaxId}<br/>
                        {selectedCompany.companyEmail} | {selectedCompany.companyPhone}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row" style={{ marginTop: 50, marginBottom:20 }}>
              <div className="col-lg-12">
                <h2>דו״ח כללי | {selectedCompany?.companyName} | {selectedSite?.projectName}</h2>
              </div>
            </div>
            <div className="row">
              <BoxesFlat
                selectedSite={selectedSite}
                companyCommittees={companyCommittees}
                companyFindings={companyFindings}
                companyMachinesReports={companyMachinesReports}
                companySafetyReports={companySafetyReports}
                companyTrainings={companyTrainings}
                selectedCompany={selectedCompany}
                companyEmployees={companyEmployees}
                companySafetyTests={companySafetyTests}
              />
            </div>
            <div className="row" style={{ marginTop: 50, marginBottom:20 }}>
              <div className="col-lg-12">
                <h2>ממצאים פתוחים</h2>
              </div>
            </div>
            <div className="row" style={{ marginTop: 20, marginBottom:20 }}>
              <div className="col-lg-12">
              {filteredFindings.length > 0 ? (
                  <>
                    {filteredFindings.map((item) => (
                      <>
                        <HpFindingRow
                          findingsType={safetyReportsAreas}
                          projects={allProjects}
                          companiesArray={companies}
                          employeesArr={employees}
                          item={item}
                        />
                      </>
                    ))}
                  </>
                ) : (<><p className="db-box-title" style={{ color: "#000" }}>לא קיימים ממצאים פתוחים</p></>)}
              </div>
            </div>
            <div className="row" style={{ marginTop: 50, marginBottom:20 }}>
              <div className="col-lg-12">
                <h2>התראות</h2>
              </div>
            </div>
            <div className="row" style={{ marginTop: 50, marginBottom:0 }}>
              <div className="col-lg-12">
              {
            selectedCompany && selectedSite && (<>


                <div className="notification-dashboard-title">התראות ועדות בטיחות</div>
                
                {filteredCommittees.length > 0 ? (
                  <>
                    {filteredCommittees.map((item) => (
                      <>
                        <NotifyCommittee type="committee" item={item} />
                      </>
                    ))}
                  </>
                ) : (
                  <p>לא קיימות התראות פעילות</p>
                )}
                <div className="notification-dashboard-title">
                  התראות עובדים ורישיונות
                </div>
                {filteredEmployees.length > 0 ? (
                  <>
                    {filteredEmployees.map((item) => (
                      <>
                        <NotifyLicense item={item} type="license" />
                      </>
                    ))}
                  </>
                ) : (
                  <p>לא קיימות התראות פעילות</p>
                )}
                <div className="notification-dashboard-title">
                  התראות הדרכות
                </div>

                {filteredTrainingGroups.length > 0 ? (
                  <>
                    {filteredTrainingGroups.map((item) => (
                      <>
                        <TrainingNotify item={item} />
                      </>
                    ))}
                  </>
                ) : (
                  <p>לא קיימות התראות פעילות</p>
                )}

                <div className="notification-dashboard-title">
                  התראות תסקירים תקופתיים
                </div>
                {filteredMachinesReports.length > 0 ? (
                  <>
                    {filteredMachinesReports.map((item) => (
                      <>
                        <NotifyMachine
                          allMachines={allMachines}
                          item={item}
                          type="machine"
                        />
                      </>
                    ))}
                  </>
                ) : (
                  <p>לא קיימות התראות פעילות</p>
                )}





            </>)
          }
              </div>
            </div>

            </div>

          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleDownloadPdf} className="btn btn-success btn-lg">שמירת קובץ</button>
          </Modal.Footer>
        </Modal>



      </UseDashboardAction>
    </>
  );
};

export default Dashboard;
