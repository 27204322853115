import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { database } from "../../services/firebase-config";
import {collection,doc,addDoc,deleteDoc,updateDoc} from "firebase/firestore";
import { Modal } from "bootstrap";
import useDashboardStore from "../../store/useDashboardStore";
import PlanItem from "./PlanItem";

const Plans = () => {

  const { plans, addManagerNewPlan,removePlanById } = useDashboardStore();

  const modalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [actions, setActions] = useState(true);
  const [planName, setPlanName] = useState('');
  const [companies, setCompanies] = useState('');
  const [sites, setSites] = useState("");
  const [employees, setEmployees] = useState("");
  const [users, setUsers] = useState("");
  const [payurl, setPayurl] = useState("");
  const [planCost, setPlanCost] = useState("");
  const [displayInSite, setDisplayInSite] = useState(true);

  const addNewPopup = async () => {
    if (
      planName !== "" &&
      planCost !== "" &&
      users !== "" &&
      payurl !== "" &&
      sites !== "" &&
      companies !== "" &&
      employees !== ""
    ) {
      try {
        const _plan = {
          createdAt: Date.now(),
          planName,
          planCost,
          users,
          companies,
          sites,
          payurl,
          actions,
          employees,
          displayInSite
        };

        const docRef = await addDoc(
          collection(database, "plans"),
          _plan
        );
        _plan.id = docRef.id;

        addManagerNewPlan(_plan);

        // if (modalRef.current) {
        //   const modalInstance = Modal.getInstance(modalRef.current);
        //   if (modalInstance) {
        //     modalInstance.hide();
        //   } else {
        //     new Modal(modalRef.current).hide();
        //   }

        //   setTimeout(() => {
        //     document
        //       .querySelectorAll(".modal-backdrop.fade.show")
        //       .forEach((backdrop) => backdrop.remove());
        //     document.body.classList.remove("modal-open");
        //   }, 150);
        // }

        setIsLoading(false);
        setCompanies("");
        setEmployees("");
        setPayurl("");
        setPlanCost("");
        setPlanName("");
        setSites("");
        setUsers("");
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("חובה למלא את כל השדות בטופס");
    }
  };

  const removePopup = async(id) => {
    const isConfirmed = window.confirm("האם למחוק את המסלול לצמיתות?");
    if(isConfirmed){
        try {
            await deleteDoc(doc(database, "plans", id))
            .then((res) => {
                removePlanById(id);
                toast.success("המסלול הוסר בהצלחה");
              }).catch((err) => {
                toast.error(err.message);
              });
        } catch (error) {
            toast.error(error.message)
        }
    }
  }

  const updatePopup = async(
    id,
    displayInSite,
    planName,
    planCost,
    users,
    companies,
    sites,
    payurl,
    actions,
    employees,
    payId) => {
    try {
        const updateData = {
            displayInSite,
            planName,
            planCost,
            users,
            companies,
            sites,
            payurl,
            actions,
            employees,
            payId};

            const popupRef = doc(database, "plans", id);
            await updateDoc(popupRef, {
                ...updateData
              }).then(() => {
                useDashboardStore.getState().updatePlanStore(id, { ...updateData});
              });
    } catch (error) {
        toast.error(error.message);
    }
  }

  return (
    <>
      <ToastContainer />

      <div
        className="modal fade"
        id="exampleModal"
        ref={modalRef}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">הוספת מסלול חדש</h1>
              <button type="button"className="btn-close"data-bs-dismiss="modal"aria-label="Close"></button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                <label className="form-label">
                    שם המסלול
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                    type="text"
                    className="form-control"/>
                </div>

                <div className="col-lg-6">
                <label className="form-label">
                    עלות חודשית
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={planCost}
                    onChange={(e) => setPlanCost(e.target.value)}
                    type="text"
                    className="form-control"/>

                </div>



                <div className="col-lg-3">
                <label className="form-label">
                    חברות
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={companies}
                    onChange={(e) => setCompanies(e.target.value)}
                    type="text"
                    className="form-control"/>
                </div>

                <div className="col-lg-3">
                <label className="form-label">
                    אתרים
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={sites}
                    onChange={(e) => setSites(e.target.value)}
                    type="text"
                    className="form-control"/>
                </div>

                <div className="col-lg-3">
                <label className="form-label">
                    יוזרים
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={users}
                    onChange={(e) => setUsers(e.target.value)}
                    type="text"
                    className="form-control"/>
                </div>

                <div className="col-lg-3">
                <label className="form-label">
                    עובדים
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={employees}
                    onChange={(e) => setEmployees(e.target.value)}
                    type="text"
                    className="form-control"/>
                </div>




                <div className="col-lg-12">
                <label className="form-label">
                    קישור לעמוד תשלום
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    value={payurl}
                    onChange={(e) => setPayurl(e.target.value)}
                    type="text"
                    style={{textAlign:'left', direction:'ltr'}}
                    className="form-control"/>
                </div>

                <div className="col-lg-4">
                <input
                    onChange={(e) => setActions(e.target.checked)}
                    checked={actions}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">
                    אפשר ביצוע פעולות אוטומציה
                  </label>
                </div>

                <div className="col-lg-4">
                <input
                    onChange={(e) => setDisplayInSite(e.target.checked)}
                    checked={displayInSite}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">
                    הצג באתר
                  </label>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  סגירה
                </button>

                {isLoading ? (
                  <>
                    <div class="spinner-border text-success" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      onClick={addNewPopup}
                      type="button"
                      className="btn btn-success">הוספת מסלול חדש</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>ניהול מסלולים במערכת</h3>
        </div>
        <div className="col-lg-2 heading-btn-container">
          <button
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="btn btn-success">הוספת מסלול</button>
        </div>
      </div>

      <div className="row" style={{ padding: 50 }}>
        {plans.length > 0 ? (
          <>
          <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">שם המסלול</th>
                    <th scope="col">עלות חודשית</th>
                    <th scope="col">חברות</th>
                    <th scope="col">אתרים</th>
                    <th scope="col">יוזרים</th>
                    <th scope="col">עובדים</th>
                    <th scope="col">פעולות</th>
                    <th scope="col">הצגה באתר</th>
                    <th scope="col">קישור</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>

                {plans.map((item) => (
                    <PlanItem item={item} removePopup={removePopup} updatePopup={updatePopup} />
                ))}
                </tbody>
            </table>
          </>
        ) : (
          <>
            <p>לא נמצאו פופאפים לתצוגה</p>
          </>
        )}
      </div>
    </>
  );
};

export default Plans;
