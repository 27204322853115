import React, {useState,useRef} from "react";
import moment from "moment";

const PopupItem = (props) => {

    const [isWebDisplay, setIsWebDisplay] = useState(props.item.isWebDisplay);
    const [isMobDisplay, setIsMobDisplay] = useState(props.item.isMobDisplay);
    const [isActive, setIsActive] = useState(props.item.isActive);
    const [popupName, setPopupName] = useState(props.item.popupName);
    const [popupTitle, setPopupTitle] = useState(props.item.popupTitle);
    const [popupContent, setPopupContent] = useState(props.item.popupContent);
    const [popupTrigger, setPopupTrigger] = useState(props.item.popupTrigger);
    const [popupAudience, setPopupAudience] = useState(props.item.popupAudience);
    const [link, setLink] = useState(props.item.link);
    const [isEditView, setIsEditView] = useState(false);
    const [uploadImage, setUploadImage] = useState("");
    const fileInputRef = useRef(null);


    return(
        <>
        {
            isEditView ? (<>
                <tr key={props.item.id}>
                    <td colSpan={9}>
                    <div className="row">
                        <div className="col-lg-3">
                        <label className="form-label">בחירת טריגר <span style={{ color: "red" }}>*</span></label>
                        <select
                            value={popupTrigger}
                            onChange={(e) => setPopupTrigger(e.target.value)}
                            className="form-control">
                            <option value="סגירה אוטומטית">סגירה אוטומטית</option>
                            <option value="סגירה בקליק">סגירה בקליק</option>
                        </select>
                        </div>
                        <div className="col-lg-3">
                        <label className="form-label">קהל תצוגה <span style={{ color: "red" }}>*</span></label>
                        <select
                            value={popupAudience}
                            onChange={(e) => setPopupAudience(e.target.value)}
                            className="form-control">
                            <option value="כל המשתמשים">כל המשתמשים</option>
                            <option value="מנהל ראשי">מנהל ראשי</option>
                            <option value="מנהלים">מנהלים</option>
                            <option value="משתמשים">משתמשים</option>
                        </select>
                        </div>
                        <div className="col-lg-3">
                        <label className="form-label">
                            שם הפופאפ (לא מוצג למשתמש)
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            value={popupName}
                            onChange={(e) => setPopupName(e.target.value)}
                            type="text"
                            className="form-control"
                        />
                        </div>
                        <div className="col-lg-3">
                        <label className="form-label">כותרת הפופאפ<span style={{ color: "red" }}>*</span></label>
                        <input
                            value={popupTitle}
                            onChange={(e) => setPopupTitle(e.target.value)}
                            type="text"
                            className="form-control"
                        />
                        </div>

                        <div className="col-lg-12">
                        <label className="form-label">קישור לעמוד</label>
                        <input
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                            type="text"
                            className="form-control"
                            style={{textAlign:'left', direction:'ltr'}}
                        />
                        </div>


                        <div className="col-lg-12">
                        <label className="form-label">
                            תוכן הפופאפ <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                            className="form-control"
                            onChange={(e) => {
                            setPopupContent(e.target.value);
                            }}>{popupContent}</textarea>
                        </div>

                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                    <label className="form-label">העלאת תמונה</label>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    setUploadImage(e.target.files[0]);
                  }}
                />
                                    </div>
                                </div>


                                <div className="row">



                <div className="col-lg-4">
                  <input
                    onChange={(e) => setIsActive(e.target.checked)}
                    checked={isActive}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">פופאפ פעיל</label>
                </div>
                <div className="col-lg-4">
                  <input
                    onChange={(e) => setIsMobDisplay(e.target.checked)}
                    checked={isMobDisplay}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">
                    הצגת הפופאפ באפליקציה
                  </label>
                </div>
                <div className="col-lg-4">
                  <input
                    onChange={(e) => setIsWebDisplay(e.target.checked)}
                    checked={isWebDisplay}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">הצגת הפופאפ באתר</label>
                </div>


                    </div>
                    </td>
                    <td>
                        <button onClick={() => {setIsEditView(!isEditView)}} className="btn btn-warning btn-sm">ביטול</button>
                        <button onClick={() => {
                            props.updatePopup(props.item.id,isWebDisplay,isMobDisplay,isActive,popupAudience,popupContent,popupName,popupTitle,popupTrigger,uploadImage,link);
                            setIsEditView(!isEditView);
                        }} style={{marginRight:6}} className="btn btn-success btn-sm">שמירה</button>
                    </td>
                </tr>
            </>) : (
                <tr key={props.item.id}>
                    <td>{moment(props.item.createdAt).format('DD/MM/YYYY')}</td>
                    <td>
                        {
                            props.item.imageUrl ? (<>
                                <img src={props.item.imageUrl} style={{width:60}} />
                            </>) : (<>
                                <p>ללא תמונה</p>
                            </>)
                        }
                    </td>
                    <td>{props.item.popupName}</td>
                    <td>{props.item.popupTitle}</td>
                    <td>{props.item.isActive ? 'פעיל' : 'לא פעיל'}</td>
                    <td>{props.item.isWebDisplay ? 'מוצג' : 'לא מוצג'}</td>
                    <td>{props.item.isMobDisplay ? 'מוצג' : 'לא מוצג'}</td>
                    <td>{props.item.popupAudience}</td>
                    <td>{props.item.popupTrigger}</td>
                    <td>
                        <button onClick={() => {props.removePopup(props.item.id)}} className="btn btn-danger btn-sm">הסרה</button>
                        <button onClick={() => {setIsEditView(!isEditView)}} style={{marginRight:6}} className="btn btn-success btn-sm">עריכה</button>
                        <button onClick={() => {props.displayPopup(props.item)}} style={{marginRight:6}} className="btn btn-info btn-sm">תצוגה</button>
                    </td>
                </tr>
            )
        }
        



        </>
    )
}

export default PopupItem;