import moment from "moment";
import React, { useState,useEffect } from "react";
import { BsFillFilePdfFill } from "react-icons/bs";

const TrainingGroupItemExp = (props) => {
  const [selectedEmployees, setSelectedEmployees] = useState(props.group.employees);
  const [employeesArr, setEmployeesArr] = useState(props.employeesArr);

  const handleRemove = (emp) => {
    setSelectedEmployees(selectedEmployees.filter((v) => v.id !== emp.id));
    setEmployeesArr([...employeesArr, emp]); // Add back to the dropdown options
  };

  const handleSelect = (empId) => {
    const emp = props.employeesArr.find((x) => x.id === empId); // Use the original prop to find the employee
    if (emp && !selectedEmployees.some((e) => e.id === emp.id)) {
      setSelectedEmployees([...selectedEmployees, emp]);
    }
  };

  useEffect(() => {
    setEmployeesArr(
      props.employeesArr.filter(
        (e) => !selectedEmployees.some((se) => se.id === e.id)
      )
    );
  }, [selectedEmployees, props.employeesArr]);




  let numberOfDays = 0;

  const calculateDays = () => {
    var startDate = moment(props.group.trainingNextDate).format("MM/DD/YYYY");
    var currenDate = moment(new Date()).format("MM/DD/YYYY");
    numberOfDays = datediff(parseDate(currenDate), parseDate(startDate));
  };

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  function parseDate(str) {
    var mdy = str.split("/");
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }

  calculateDays();


  let textColor = "#000000";
  if (numberOfDays <= 0) {
    textColor = "#EB6079";
  } else if (numberOfDays > 0 && numberOfDays < 30) {
    textColor = "#ff9900";
  } else {
    textColor = "#000000";
  }


  return (
    <>

          <div className="row rowshadowboxExp">
            <div className="col-lg-5">
              <h6 style={{fontSize:24}}>{props.group.trainingName}</h6>
              <p style={{fontSize:19}}>{props.group.trainingDesc}</p>
              <p style={{fontSize:19}}>
                <b>השתתפו בהדרכה: </b>
                {props.group.employees.map((emp) => (
                  <>
                    {emp.firstName} {emp.lastName},{" "}
                  </>
                ))}
              </p>
            </div>

            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p>תאריך ההדרכה</p>
              <h4>{moment(props.group.trainingDate).format("DD/MM/YYYY")}</h4>
            </div>

            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p>ההדרכה הבאה</p>
              <h4>{moment(props.group.trainingNextDate).format("DD/MM/YYYY")}</h4>
              <p style={{ color: textColor }}>{numberOfDays} ימים</p>
            </div>

            <div className="col-lg-1" style={{ textAlign: "center" }}>
            <p>קבצים ({props.group.trainingFiles.length})</p>
              {
                props.group.trainingFiles.length > 0 && (<>
                {
                  props.group.trainingFiles.map((file) => (
                  <a href={file} target="_blank" rel="noreferrer"><BsFillFilePdfFill color="#EB6079" size={30} /></a>))
                }
                </>)
              }
              </div>
          </div>
    </>
  );
};

export default TrainingGroupItemExp;
